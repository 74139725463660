import { makeStyles } from "@mui/styles";
import { squareShineColors } from "src/consts/app-config";

const {
    BACKLIGHT_DARKBLUE_COLOR,
    BACKLIGHT_DARKGREEN_COLOR,
    BACKLIGHT_DEEPBLACK_COLOR,
} = squareShineColors;

export const useStyles = makeStyles({
    "@global": {
        "@keyframes deepBlackAppearRound": {
            "0%": {
                opacity: 0,
            },

            "100%": {
                opacity: 1,
            },
        },
    },

    noselect: {
        "-webkit-touch-callout": "none",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none",
        touchAction: "none",
        width: "12.5%",
        height: "12.5%",
        top: "auto",
        position: "absolute",
        backgroundSize: "100%",
        zIndex: 100,
        left: 0,
        transition: "ease-in 0.15s all",
        "&.isHighlight": {
            borderRadius: "50%",
            transition: "ease-in 0.25s all",
            background: "transparent",
            "&.darkBlue": {
                background: BACKLIGHT_DARKBLUE_COLOR,
            },
            "&.darkGreen": {
                background: BACKLIGHT_DARKGREEN_COLOR,
            },
            "&.deepBlack": {
                background: `radial-gradient(circle, ${BACKLIGHT_DEEPBLACK_COLOR} 30%, rgba(59,59,117,0) 30%)`,
                opacity: 0,
                animationName: "deepBlackAppearRound",
                animationIterationCount: "1",
                animationFillMode: "forwards",
                animationDuration: "400ms",
            },
        },
    },

    squareBox: {
        position: "relative",
        width: "100%",
        height: "100%",
        "&.hoverMobile": {
            "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "200%",
                height: "200%",
                // backgroundColor: "rgba(0,0,0,0.2)",
                background: BACKLIGHT_DEEPBLACK_COLOR,
            },
        },
        "&.hoverDesktop": {
            background: `${BACKLIGHT_DEEPBLACK_COLOR}`,
        },
    },
});
