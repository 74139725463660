import React, { memo, useCallback, useMemo } from "react";
import { useDrop } from "react-dnd";
import { observer } from "mobx-react";
import cn from "classnames";

import useStores from "src/hooks/useStores";
import { appColors, squareShineColors } from "src/consts/app-config";
import { EColor } from "src/components/types";

import iOSDetect from "../../../../utils/iOsDetect";
import { usePlayer } from "../../../../sounds/use-player";

import { useStyles } from "./styles";

interface Props {
    i: number;
    square: string;
    mobileHover: boolean;
    appearanceTheme: string;
    highlight: boolean;
    onSquareClick?: (target: string) => void;
    onDrop?: (t: { from: string; to: string }) => void;
    startDiceElement: HTMLElement | null;
    finalDiceElement: any;
    unitedStartFinishFigure: string[] | null;
}

interface ComponentProps {}

const Square = (props: Props & ComponentProps) => {
    const { gameStore } = useStores();
    const classes = useStyles();

    const player = usePlayer();

    const isHighlightMove = "isHighlight";
    const emptyPieces = "empty";

    const {
        onDrop,
        onSquareClick,
        i,
        square,
        mobileHover,
        highlight,
        startDiceElement,
        finalDiceElement,
        unitedStartFinishFigure,
    } = props;
    const lastSuccessMove = unitedStartFinishFigure;
    const currentSquare = gameStore.gameState.currentSquare;
    const actionDropMove = gameStore.gameState.actionDropMove;
    const isHighlight = highlight ? isHighlightMove : null;

    const [{ isOver }, drop] = useDrop({
        accept: [
            "wp",
            "wr",
            "wn",
            "wb",
            "wq",
            "wk",
            "bp",
            "br",
            "bn",
            "bb",
            "bq",
            "bk",
            emptyPieces,
        ],
        drop: (props) => {
            gameStore.gameState.ddFinishAction(i);
            //@ts-ignore
            onDrop && onDrop({ from: props.from, to: square });
            // iOSDetect() && player("pieceSoundPlay");
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const getPos = useMemo(() => {
        //check second click + move from store include target id + start elem include start cell
        if (
            finalDiceElement &&
            lastSuccessMove &&
            finalDiceElement?.id.includes(lastSuccessMove[1]) &&
            startDiceElement?.id.includes(square)
        ) {
            //get only translate(xxx%, y%%%) exclude scale and other params
            //@ts-ignore
            const finalDicePosSpread = [
                ...finalDiceElement.style.transform.match(/\d+/g),
            ];

            return `translate(${finalDicePosSpread[0]}%, ${finalDicePosSpread[1]}%)`;
        }

        return `translate(${(i % 8) * 100}%,${Math.trunc(i / 8) * 100}%)`;
    }, [finalDiceElement, isHighlight]);

    return (
        <div
            ref={drop}
            id={`gamePiece-square-${square}`}
            key={i}
            className={cn(classes.noselect, isHighlight, props.appearanceTheme)}
            style={{
                transform: getPos,
            }}
            onMouseDown={() => {
                onSquareClick && onSquareClick(square);
            }}
            onTouchStart={() => {
                onSquareClick && onSquareClick(square);
            }}
            onTouchMove={() => {
                onSquareClick && onSquareClick(square);
            }}
        >
            <div
                data-type={emptyPieces}
                className={cn(classes.squareBox, {
                    hoverMobile: isOver && mobileHover && isHighlight,
                    hoverDesktop: isOver && !mobileHover && isHighlight,
                })}
            />
        </div>
    );
};

const MemoizedSquare = memo(Square);

const SquareWrapper = (props: Props) => {
    const { gameStore } = useStores();

    return (
        <Square
            {...props}
            //highlight={squareStyles[props.square]}
            //onSquareClick={onSquareClick}
            //onDrop={onDrop}
        />
    );
};

export default observer(SquareWrapper);
