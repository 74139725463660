import React, { CSSProperties, useRef, useEffect } from "react";
import { useDragLayer } from "react-dnd";

import { IPiecesMap } from "./types";

const layerStyles: CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    left: 0,
    top: 0,
    zIndex: 1000000,
};
interface IGetItemStylesParams {
    initialOffset?: number;
    currentOffset?: number;
    clientOffset: {
        x: number;
        y: number;
    };
    width: number;
    height: number;
    isMultiTable: boolean;
}
const getItemStyles = (props: IGetItemStylesParams): any => {
    const {
        initialOffset,
        currentOffset,
        clientOffset,
        width,
        height,
        isMultiTable,
    } = props;

    if (!initialOffset || !currentOffset) {
        return {
            display: "none",
        };
    }
    const { x, y } = clientOffset;

    const transform = `translate(${x - width / 2}px, ${y - height / 2}px)`;

    // const transform = isMultiTable
    //     ? `translate(${x - width / 2}px, ${y - height / 2}px) scale(0.88)`
    //     : `translate(${x - width / 2}px, ${y - height / 2}px)`;

    return {
        position: "relative",
        transform,
        WebkitTransform: transform,
    };
};

interface Props {
    pieces: IPiecesMap;
    isMultiTable: boolean;
    equalizeDrag: number;
    isDraggingFunc: (params: boolean) => void;
}
const PieceDragLayer = (props: Props) => {
    const {
        type,
        item,
        itemType,
        isDragging,
        clientOffset,
        initialOffset,
        currentOffset,
    } = useDragLayer((monitor) => ({
        type: monitor.getItemType(),
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        clientOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    const { pieces, equalizeDrag, isDraggingFunc } = props;
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        isDraggingFunc(isDragging);
    }, [isDragging]);

    if (!isDragging || !itemType) {
        return null;
    }

    return (
        <div style={layerStyles}>
            <div
                ref={ref}
                style={getItemStyles({
                    initialOffset: initialOffset?.x,
                    currentOffset: currentOffset?.x,
                    clientOffset: clientOffset ?? { x: 0, y: 0 },
                    width: ref?.current?.offsetWidth || equalizeDrag,
                    height: ref?.current?.offsetHeight || equalizeDrag,
                    isMultiTable: props.isMultiTable,
                })}
            >
                {pieces[type!]}
            </div>
        </div>
    );
};

export default PieceDragLayer;
