import axios from 'axios';

import endpoints from 'src/core/endpoints';
import {
    ITournaments,
    ITopPlayers,
    IRequestTopPlayers,
} from 'src/store/models';

import {
    ITournamentCreateRequest,
    ITournamentJoinRequest,
    IResponseTopPlayers,
} from './requestResponses';

export const createTournaments = async (params: ITournamentCreateRequest) => {
    return await axios.post(endpoints.createTournament, params, {
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin': 'true',
            'Content-Type': 'application/json',
        },
    });
};

export const getTournaments = async () => {
    return await axios.get<ITournaments[]>(endpoints.getTournaments);
};

export const getCurrentTournament = async (id: string) => {
    return await axios.get(`${endpoints.getCurrentTournament}${id}`);
};

export const joinTournaments = async (params: ITournamentJoinRequest) => {
    return await axios.post(endpoints.joinTournaments, params);
};

export const cancelJoinTournaments = async (params: ITournamentJoinRequest) => {
    return await axios.post(endpoints.cancelJoinTournaments, params);
};

export const deleteAllTournaments = async () => {
    return await axios.delete(endpoints.removeAllTournaments);
};

//export const getTopPlayers = async (params: IResponseTopPlayers) => {
//    return await axios.get<IRequestTopPlayers>(endpoints.getTopPlayers, {
//        params,
//    });
//};
