import cn from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import { useLocale } from "src/providers/LocaleProvider";
import { MAX_XL_TABLET } from "src/pages/game-new2/constants";
import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";
import { GameChatType, ColorVariant, ScreenType } from "src/store/models";
import ToSingleGame from "src/assets/icons/multitable/toSingleGame";
import DiceButton from "src/components/controls/dice-button";
import PiecesComponent from "../../components/center/piecesComponent";

import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";
import useWindowSize from "src/hooks/useWindowSize";
import { windowsSizeParams } from "src/consts/window-size-params";

import { LeftLayoutPropsContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";

import { useStyles } from "./styles";
import LeftLayout from "src/pages/game-new2/layouts/components/left";
import RightLayout from "src/pages/game-new2/layouts/components/right";
import BoardV2 from "src/components/BoardV2/index";

interface Props {
    //LEGACY
    diceClickRnd?: number;
    setDiceClickRnd?: any;
    rndPrefix?: number;
    itemHeight?: number | null;
    itemWidth?: number | null;
    tabletRatio: boolean | undefined;
    gameContainerRef: HTMLDivElement | null;
}

const DesktopGameChessboardWrapper = (props: Props) => {
    const { gameStore, gamesStore, authStore } = useStores();
    const [middleSizeState, setSize] = useState<any>();
    const { width, height } = useWindowSize();
    const desktopLayoutContainerRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const history = useHistory();
    const {
        game: {
            multiTable: { toSoloGame },
        },
    } = useLocale();
    const desktopRightItemRef = useRef<HTMLDivElement>(null);
    const desktopRightItemWidth = desktopRightItemRef?.current?.offsetWidth;
    const desktopRightItemHeight = desktopRightItemRef?.current?.offsetHeight;
    const gameContainerWidth = desktopLayoutContainerRef?.current?.offsetWidth;
    const gameContainerHeight =
        desktopLayoutContainerRef?.current?.offsetHeight;

    const winner = gameStore?.currentGameNew?.winner;
    const [elemHeight, setHeight] = useState<number | undefined>();

    const { isMultiTable, isTwoGameTable, currentGame } = useChessBoardProps();

    const style = useStyles({ elemHeight });
    const isOneActiveGame = gamesStore.gameTables.filter(
        (table) => table.state === "game"
    )?.length;
    const chatDesktopType = GameChatType.DESKTOP;

    const viewMode = gameStore.isViewMode();
    const [chessBoardHeight, setChessBoardHeight] = useState<number>();

    // const myColor = useMemo(() => {
    //     return viewMode ? ColorVariant.white : gameStore.gameState.getMyColor();
    // }, [viewMode, location.pathname, gameStore.gameId]);
    //====
    const isVisible = authStore.isVisibleWebApp;

    const killedPieces = useMemo(() => {
        return gameStore.gameState.killedPiecesNew;
    }, [gameStore.stateNew.movesHistory]);

    const myMove = useMemo(() => {
        return gameStore.isMyMove;
    }, [gameStore.isMyMove]);

    const getMiddleSize = <
        T extends number | undefined,
        U extends number | undefined,
        P extends number,
        C extends number,
        O extends number
    >(
        gameContainerWidth: T,
        gameContainerHeight: U,
        piecesWrapper: P,
        paddingCorrector: C,
        bottomOffset: O
    ): number | undefined => {
        if (gameContainerWidth && gameContainerHeight && height) {
            let calcWidth = gameContainerWidth - paddingCorrector * 4;
            let calcHeight =
                gameContainerHeight -
                paddingCorrector * 2 -
                (height > MAX_XL_TABLET ? bottomOffset : 0);
            let piecesSpace = piecesWrapper * 2;
            let maxSize = calcWidth / 2 - piecesSpace;
            let calcSize = Math.min(calcWidth, calcHeight) - piecesSpace;
            if (maxSize >= calcSize) return calcSize;
            else return maxSize;
        }
        return undefined;
    };

    const getLeftBlockSize = <
        W extends number | undefined,
        M extends number | undefined,
        C extends number,
        O extends number
    >(
        gameContainerWidth: W,
        middleSize: M,
        leftComponentOffset: O,
        paddingCorrector: C
    ): number | string => {
        if (gameContainerWidth && middleSize) {
            let calcWidth = gameContainerWidth - paddingCorrector * 4;
            return (calcWidth - middleSize) / 2 - leftComponentOffset + "px";
        }
        return "1fr";
    };

    const calcDiceSize = <
        W extends number | undefined,
        H extends number | undefined
    >(
        diceWrapperWidthParams: W,
        diceWrapperHeightParams: H
    ): number => {
        if (diceWrapperWidthParams && diceWrapperHeightParams) {
            //Max Dice size - 20% of Height
            let diceHeight = diceWrapperHeightParams * 0.2;
            let calcSize = Math.min(diceWrapperWidthParams / 3, diceHeight);
            return calcSize * 0.7;
        }
        return 65;
    };

    const getMultitableChessboardSize = <
        T extends number | undefined,
        U extends number | undefined,
        P extends number
    >(
        gameContainerWidth: T,
        gameContainerHeight: U,
        piecesBox: P
    ): number | undefined => {
        if (gameContainerWidth && gameContainerHeight) {
            let maxMinResult = Math.min(
                gameContainerWidth / 2,
                gameContainerHeight
            );
            if (gameContainerHeight - maxMinResult >= piecesBox * 2) {
                return maxMinResult;
            }
            return gameContainerHeight - piecesBox * 2;
        }
        return 300;
    };

    useEffect(() => {
        let actualSize = calcDiceSize(
            desktopRightItemWidth,
            desktopRightItemHeight
        );
        gameStore.setPiecesSize(actualSize, ScreenType.desktop);
    }, [desktopRightItemWidth, desktopRightItemHeight, isVisible]);

    const middleSize = useMemo(
        () =>
            getMiddleSize(
                gameContainerWidth,
                gameContainerHeight,
                60,
                16,
                windowsSizeParams.desktopAppBarHeightNew
            ),
        [
            gameContainerWidth,
            gameContainerHeight,
            width,
            height,
            desktopLayoutContainerRef,
        ]
    );

    const leftBlockSize = useMemo(
        () => getLeftBlockSize(gameContainerWidth, middleSize, 0, 16),
        [gameContainerWidth, width, height, middleSize]
    );

    const minSizeMultitable = useMemo(
        () =>
            getMultitableChessboardSize(
                gameContainerWidth,
                gameContainerHeight,
                30
            ),
        [gameContainerWidth, gameContainerHeight, width, height]
    );

    // useEffect(() => {
    //     if (!viewMode) return;
    //     if (gameStore.nextRound) {
    //         history.push(`/game/${gameStore.nextRound.gameId}`);
    //     }
    // }, [viewMode, gameStore.nextRound]);

    const myColor = useMemo(() => {
        return gameStore.getMyColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
    ]);

    const opponentColor = useMemo(() => {
        return gameStore.getOpponentColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
    ]);

    const fen = gameStore?.currentGameNew?.fen;

    if (!fen) return;

    return (
        <>
            <div
                ref={desktopLayoutContainerRef}
                className={cn(style.desktopLayoutContainer, {
                    isMultiTable,
                    myMove: myMove && !winner,
                    opponentMove: !myMove && !winner,
                })}
            >
                <>
                    <div className={style.desktopLeftBox}>
                        <LeftLayoutPropsContext.Provider
                            value={{
                                chatDesktopType,
                            }}
                        >
                            <div
                                className={cn(
                                    style.desktopLeftRightItem,
                                    "left"
                                )}
                            >
                                <LeftLayout />
                            </div>
                        </LeftLayoutPropsContext.Provider>
                    </div>

                    <div
                        className={cn(style.desktopCenterBox, {
                            isMultiTable,
                        })}
                        style={{}}
                    >
                        <div
                            className={cn(
                                style.desktopCenterPiecesWrapper,
                                "top",
                                {
                                    isMultiTable,
                                }
                            )}
                        >
                            <PiecesComponent
                                killedPieces={killedPieces[myColor]}
                                color={opponentColor}
                                desktopWidth={middleSize}
                            />
                        </div>
                        <div
                            className={cn(style.desktopCenterSquaredWrapper, {
                                isMultiTable,
                            })}
                        >
                            {/*{ <ChessboardWrapper {...props} /> }*/}

                            <BoardV2 />

                            {/*{'desktop chess board'}*/}
                        </div>
                        <div
                            className={cn(
                                style.desktopCenterPiecesWrapper,
                                "bottom",
                                {
                                    isMultiTable,
                                }
                            )}
                        >
                            <PiecesComponent
                                killedPieces={killedPieces[opponentColor]}
                                color={myColor}
                                desktopWidth={middleSize}
                            />
                        </div>
                    </div>

                    <div
                        className={cn(style.desktopRightBox, {
                            isMultiTable,
                            isTwoGameTable,
                        })}
                    >
                        <div
                            ref={desktopRightItemRef}
                            className={cn(style.desktopLeftRightItem, "right")}
                        >
                            <RightLayout {...props} />
                        </div>
                    </div>
                </>
            </div>
        </>
    );

    // if (isMultiTable) {
    //     return (
    //         <>
    //             <div
    //                 className={cn(style.desktopLayoutContainer, {
    //                     isMultiTable,
    //                 })}
    //                 // style={{ gridTemplateColumns: `${middleSize}px 1fr` }}
    //             >
    //                 <>
    //                     {isMultiTable && isOneActiveGame === 1 && (
    //                         <div className={cn(style.toSingleGameWrapper)}>
    //                             <ToSingleGame
    //                                 width={24}
    //                                 height={24}
    //                                 fillColor={"white"}
    //                                 onClick={() => {
    //                                     history.push(
    //                                         `${paths.game}/${currentGame?.id}`
    //                                     );
    //                                 }}
    //                             />
    //                             <p
    //                                 className={cn(
    //                                     style.toSingleGameWrapperText,
    //                                     "showInfoText"
    //                                 )}
    //                             >
    //                                 {toSoloGame("Solo table")}
    //                             </p>
    //                         </div>
    //                     )}
    //                     <div
    //                         className={cn(style.desktopCenterBox, {
    //                             isMultiTable,
    //                         })}
    //                         style={{
    //                             height: gameContainerHeight,
    //                             maxHeight: gameContainerHeight,
    //                             width:
    //                                 gameContainerWidth &&
    //                                 gameContainerWidth / 2,
    //                             maxWidth:
    //                                 gameContainerWidth &&
    //                                 gameContainerWidth / 2,
    //                         }}
    //                     >
    //                         <div
    //                             className={cn(
    //                                 style.desktopCenterPiecesWrapper,
    //                                 "top",
    //                                 {
    //                                     isMultiTable,
    //                                 }
    //                             )}
    //                         >
    //                             <PiecesComponent
    //                                 killedPieces={killedPieces[myColor]}
    //                                 color={yourColor}
    //                                 desktopWidth={minSizeMultitable}
    //                             />
    //                         </div>
    //                         <div
    //                             className={cn(
    //                                 style.desktopCenterSquaredWrapper,
    //                                 {
    //                                     isMultiTable,
    //                                 }
    //                             )}
    //                             style={{
    //                                 width: minSizeMultitable,
    //                                 height: minSizeMultitable,
    //                                 minHeight: minSizeMultitable,
    //                                 minWidth: minSizeMultitable,
    //                             }}
    //                         >
    //                             <Chessboard
    //                                 marginTop={0}
    //                                 marginBottom={0}
    //                                 border
    //                                 fen={fen.split(" ")[0]}
    //                                 myColor={myColor}
    //                                 viewMode={viewMode}
    //                                 isMultiTable={isMultiTable}
    //                                 isMultitableСhessBoardHeight={
    //                                     minSizeMultitable
    //                                 }
    //                             />
    //                         </div>
    //                         <div
    //                             className={cn(
    //                                 style.desktopCenterPiecesWrapper,
    //                                 "bottom",
    //                                 {
    //                                     isMultiTable,
    //                                 }
    //                             )}
    //                         >
    //                             <PiecesComponent
    //                                 killedPieces={killedPieces[yourColor]}
    //                                 color={myColor}
    //                                 desktopWidth={minSizeMultitable}
    //                             />
    //                         </div>
    //                     </div>
    //                     {!props.tabletRatio && (
    //                         <div
    //                             className={cn(style.desktopRightBox, {
    //                                 isMultiTable,
    //                                 isTwoGameTable,
    //                             })}
    //                             style={{
    //                                 height: gameContainerHeight,
    //                                 minHeight: gameContainerHeight,
    //                             }}
    //                         >
    //                             <div
    //                                 ref={desktopRightItemRef}
    //                                 className={cn(
    //                                     style.desktopLeftRightItem,
    //                                     "right"
    //                                 )}
    //                             >
    //                                 <RightLayout {...props} />
    //                             </div>
    //                         </div>
    //                     )}
    //                 </>
    //             </div>
    //         </>
    //     );
    // }

    // return (
    //     <>
    //         <div
    //             className={cn(style.desktopLayoutContainer, { isMultiTable })}
    //             style={{ gridTemplateColumns: `1fr ${middleSize}px 1fr` }}
    //         >
    //             <>
    //                 {isMultiTable && isOneActiveGame === 1 && (
    //                     <div className={cn(style.toSingleGameWrapper)}>
    //                         <ToSingleGame
    //                             width={24}
    //                             height={24}
    //                             fillColor={"white"}
    //                             onClick={() => {
    //                                 history.push(
    //                                     `${paths.game}/${currentGame?.id}`
    //                                 );
    //                             }}
    //                         />
    //                         <p
    //                             className={cn(
    //                                 style.toSingleGameWrapperText,
    //                                 "showInfoText"
    //                             )}
    //                         >
    //                             {toSoloGame("Solo table")}
    //                         </p>
    //                     </div>
    //                 )}
    //                 {!isMultiTable && !props.tabletRatio && (
    //                     <div className={style.desktopLeftBox}>
    //                         <LeftLayoutPropsContext.Provider
    //                             value={{
    //                                 chatDesktopType,
    //                             }}
    //                         >
    //                             <div
    //                                 className={cn(
    //                                     style.desktopLeftRightItem,
    //                                     "left"
    //                                 )}
    //                             >
    //                                 <LeftLayout />
    //                             </div>
    //                         </LeftLayoutPropsContext.Provider>
    //                     </div>
    //                 )}
    //                 <div
    //                     className={cn(style.desktopCenterBox, {
    //                         isMultiTable,
    //                     })}
    //                 >
    //                     <div
    //                         className={cn(
    //                             style.desktopCenterPiecesWrapper,
    //                             "top",
    //                             {
    //                                 isMultiTable,
    //                             }
    //                         )}
    //                     >
    //                         <PiecesComponent
    //                             killedPieces={killedPieces[myColor]}
    //                             color={yourColor}
    //                             desktopWidth={middleSize}
    //                         />
    //                     </div>
    //                     <div
    //                         className={cn(style.desktopCenterSquaredWrapper, {
    //                             isMultiTable,
    //                         })}
    //                     >
    //                         {/* <ChessboardWrapper {...props} /> */}
    //                         <Chessboard
    //                             marginTop={0}
    //                             marginBottom={0}
    //                             border
    //                             //Depricated. shoud delete in future
    //                             fen={fen.split(" ")[0]}
    //                             myColor={myColor}
    //                             viewMode={viewMode}
    //                             isMultiTable={isMultiTable}
    //                             isMultitableСhessBoardHeight={chessBoardHeight}
    //                         />
    //                     </div>
    //                     <div
    //                         className={cn(
    //                             style.desktopCenterPiecesWrapper,
    //                             "bottom",
    //                             {
    //                                 isMultiTable,
    //                             }
    //                         )}
    //                     >
    //                         <PiecesComponent
    //                             killedPieces={killedPieces[yourColor]}
    //                             color={myColor}
    //                             desktopWidth={middleSize}
    //                         />
    //                     </div>
    //                 </div>
    //                 {!props.tabletRatio && (
    //                     <div
    //                         className={cn(style.desktopRightBox, {
    //                             isMultiTable,
    //                             isTwoGameTable,
    //                         })}
    //                     >
    //                         <div
    //                             ref={desktopRightItemRef}
    //                             className={cn(
    //                                 style.desktopLeftRightItem,
    //                                 "right"
    //                             )}
    //                         >
    //                             <RightLayout {...props} />
    //                         </div>
    //                     </div>
    //                 )}
    //             </>
    //         </div>
    //     </>
    // );
};

export default observer(DesktopGameChessboardWrapper);
