import React, {
    useEffect,
    useRef,
    useCallback,
    useState,
    useMemo,
} from "react";
import { usePageVisibility } from "react-page-visibility";
import { observer } from "mobx-react";
import {
    useHistory,
    NavLink,
    useLocation,
    useRouteMatch,
} from "react-router-dom";

import paths from "src/consts/paths";
import FastChessboard from "src/components/fastChessboard";
import iOSDetect from "src/utils/iOsDetect";
import useStores from "src/hooks/useStores";
import getPrefixFen from "src/utils/getPrefixFen";
import { Spinner } from "src/components/spinner";
import { ColorVariant, GameStatus } from "src/store/models";

import { usePlayer } from "src/sounds/use-player";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export enum LoadingStatus {
    loading,
    loaded,
    notLoaded,
}

interface IChessboardProps {
    fen: string;
    myColor: ColorVariant;
    marginTop?: number;
    marginBottom?: number;
    viewMode?: boolean;
    isMultiTable?: boolean;
    border?: boolean;
    isMultitableСhessBoardHeight?: number;
}

const ChessBoard = (props: IChessboardProps) => {
    const {
        fen,
        myColor,
        isMultiTable,
        marginTop,
        marginBottom,
        viewMode,
        isMultitableСhessBoardHeight,
    } = props;

    const location = useLocation();
    const { gameStore, authStore, viewHistoryGameStore } = useStores();
    const [pieceWidth, setPiecesSize] = useState<number>();
    const gameState = gameStore.gameState;
    const loadingStatus = gameStore.loadingStatus;
    const gameIsOver = gameStore?.currentGame?.status;

    const chessboardSize = viewMode
        ? viewHistoryGameStore?.chessboardSize
        : gameStore?.chessboardSize;

    const color = myColor;
    const pieceSoundEnabled =
        authStore.generalGameSettings?.pieceSound ?? false;
    const isVisibleApplication = usePageVisibility();
    const isVisible = authStore.isVisibleWebApp;

    const player = usePlayer();
    const playMoveSound = useCallback(() => {
        // if (pieceSoundEnabled && isVisibleApplication) player("pieceSoundPlay");
    }, [pieceSoundEnabled]);

    useEffect(() => {
        if (loadingStatus === LoadingStatus.loaded) {
            gameState.setLocalFen(fen);
        }
        gameState.setLocalFen(fen);
    }, [loadingStatus, fen, location.pathname, isVisible]);

    const prevLocalFen = usePrevious(gameState.localFen);

    useEffect(() => {
        // Логика, связанная с проигрыванием звуков хода фигуры
        if (!prevLocalFen || !gameState.localFen) return;
        if (getPrefixFen(gameState.localFen) !== getPrefixFen(prevLocalFen)) {
            !iOSDetect() && playMoveSound();
        }
    }, [gameState.localFen]);

    useEffect(() => {
        if (
            isMultitableСhessBoardHeight &&
            typeof isMultitableСhessBoardHeight === "number" &&
            isMultiTable &&
            !viewMode
        ) {
            window.innerWidth > 400
                ? setPiecesSize(isMultitableСhessBoardHeight / 8)
                : setPiecesSize(isMultitableСhessBoardHeight / 7.5);
            return;
        }
        if (chessboardSize && typeof chessboardSize === "number") {
            window.innerWidth > 400
                ? setPiecesSize(chessboardSize / 8)
                : setPiecesSize(chessboardSize / 7.5);
        }
    }, [chessboardSize, isMultitableСhessBoardHeight, isMultiTable]);

    const prefixFen = useMemo(() => {
        return getPrefixFen(gameState.localFen);
    }, [gameState.localFen]);

    return (
        <>
            {isMultiTable &&
                loadingStatus === LoadingStatus.loading &&
                gameIsOver !== GameStatus.FINISHED && <Spinner isFullscreen />}
            <FastChessboard
                marginTop={marginTop}
                marginBottom={marginBottom}
                border
                showNotationInBorder
                mobileHover={false}
                pieceWidth={pieceWidth}
                fen={prefixFen}
                // @ts-ignore
                orientation={color}
                isMultiTable={isMultiTable}
            />
        </>
    );
};
export default observer(ChessBoard);
