import axios from "axios";

import endpoints, { endpointsRoot } from "../../../core/endpoints";
import { IRank } from "../../../store/models";

import {
    IUpdateSettingsRequest,
    IUpdateGameSettingsRequest,
    IUpdateGameSettingsResponse,
    IChangePasswordRequest,
    IChangePasswordResponse,
    IChangeAvatarRequest,
    IChangeNicknameRequest,
    IChangeUserStatusRequest,
    IChangeAvatarStaticRequest,
    IChangeUserBackGroundColor,
} from "./requestResponses";
import { IMyGamesResponse } from "../myGames/requestResponses";

export const updateSettings = async (params: IUpdateSettingsRequest) => {
    return await axios.patch<IUpdateSettingsRequest>(
        endpoints.updateSettings,
        params
    );
};

export const changeGameSettings = async (
    params: IUpdateGameSettingsRequest
) => {
    return await axios.post<IUpdateGameSettingsResponse>(
        endpoints.updateProfile,
        params
    );
};

export const updatePassword = async (params: IChangePasswordRequest) => {
    return await axios.post<IChangePasswordResponse>(
        endpoints.changePassword,
        params
    );
};

export const uploadUserAvatar = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return await axios.post(endpoints.uploadAvatar, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
};

export const uploadSystemAvatar = async (id: string) => {
    return await axios.post(endpoints.uploadSystemAvatar, { id });
};

export const changeAvatar = async (params: IChangeAvatarRequest) => {
    return await axios.post(endpoints.uploadAvatar, params, {
        headers: { "Content-Type": "multipart/form-data" },
    });
};

export const getAvatarList = async () => {
    return await axios.get(endpoints.getAvatarList);
};

export const changeStaticAvatar = async (
    params: IChangeAvatarStaticRequest
) => {
    return await axios.post(endpoints.uploadStaticAvatar, params);
};

export const changeNickname = async (params: IChangeNicknameRequest) => {
    return await axios.post(endpoints.changeNickname, params);
};

export const changeUserStatus = async (params: IChangeUserStatusRequest) => {
    return await axios.post(endpoints.changeUserStatus, params);
};

export const takeRakeBack = async () => {
    return await axios.post(endpoints.takeRakeBack);
};

export const changeBackGround = async (params: IChangeUserBackGroundColor) => {
    return await axios.post(endpoints.changeBackGroundColor, params);
};

export const getRanks = async () => {
    return await axios.get<{ ranks: IRank[] }>(endpoints.getRanks);
};

export const getGameHistory = async () => {
    return await axios.get<IMyGamesResponse>(`${endpointsRoot}/api/3/game/history`);
};