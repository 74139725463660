import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { textAlign } from "@mui/system";

import { ColorVariant } from "src/store/models";
import Countdown, { zeroPad } from "react-countdown";
import { usePlayer } from "src/sounds/use-player";
import shortColor from "src/utils/shortColor";
import useChangeTheme from "src/hooks/useChangeTheme";

import { useStyles } from "./styles";
import useStores from "../../../../hooks/useStores";

type RedesignClockProps = {
    warningLimit: number;
    active: boolean;
    activeClock?: { color: ColorVariant };
    initTime: number;
    noTimerSound: boolean;
    color: ColorVariant;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    gameEnd?: boolean;
    isViewMode?: boolean;
};

export const RedesignClock = memo(
    ({
        warningLimit,
        initTime,
        activeClock,
        noTimerSound,
        color,
        isMultiTable,
        isTwoGameTable,
        gameEnd,
        isViewMode,
        ...props
    }: RedesignClockProps) => {
        const [gameTime, setGameTime] = useState(0);
        const currentWidth = window.innerWidth;
        const appearanceTheme = useChangeTheme();

        const { gameStore } = useStores();

        useEffect(() => {
            if (gameTime !== initTime) {
                setGameTime(initTime);
            }
        }, [initTime]);

        const seconds = gameTime / 1000;
        const classes = useStyles({
            warning: warningLimit >= seconds,
            appearanceTheme,
            gameEnd,
            ...props,
        });
        // useEffect(() => {
        //     if (gameTime && gameTime / 1000 < 20) {
        //         if (!noTimerSound) soundPlayer('timeoutSoundPlay');
        //     }
        // }, [gameTime]);

        // console.log("activeClock", activeClock);

        useEffect(() => {
            let timer;
            if (
                activeClock &&
                activeClock.color === shortColor(color) &&
                !gameEnd
            ) {
                timer = setInterval(() => {
                    setGameTime((gameTime) =>
                        gameTime - 1000 <= 0 ? 0 : gameTime - 1000
                    );
                }, 1000);

                return () => {
                    clearInterval(timer);
                };
            }
            if (gameEnd) {
                const timerEnd = setTimeout(() => {
                    setGameTime((gameTime) =>
                        gameTime - 1000 <= 0 ? 0 : gameTime - 1000
                    );
                }, 1000);

                return () => {
                    clearTimeout(timerEnd);
                };
            }
        }, [
            activeClock,
            gameEnd,
            gameStore?.currentGameNew?.matchId,
            gameStore?.currentGameNew?.matchId2,
        ]);

        const desktopStyleProps = {
            // TODO: maybe we should set this values in useStyles?
            paddingLeft: !isMultiTable
                ? currentWidth > 1250
                    ? "0%"
                    : "0px"
                : undefined,
        };

        const timeText = moment()
            .startOf("day")
            .seconds(seconds)
            .format("mm:ss");

        return (
            <div
                style={{ ...desktopStyleProps }}
                className={cn(
                    classes.text,
                    "redesign-clock-text",
                    appearanceTheme,
                    {
                        isMultiTable,
                        isTwoGameTable,
                    }
                )}
            >
                <div
                    className={cn(classes.slicedTimerBox, appearanceTheme, {
                        isMultiTable,
                    })}
                >
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            "minutes",
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(0, 2)}
                    </span>
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            "colon",
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(2, 3)}
                    </span>
                    <span
                        className={cn(
                            classes.slicedTimerPart,
                            appearanceTheme,
                            "seconds",
                            {
                                isMultiTable,
                                isViewMode,
                            }
                        )}
                    >
                        {timeText.slice(3)}
                    </span>
                </div>
            </div>
        );
    }
);
