import { useEffect } from "react";
import { observer } from "mobx-react";
import axios from "axios";

import { useSocket } from "src/core/useSocket";
import useStores from "src/hooks/useStores";
import {
    TournamentStatus,
    IStageGame,
    ITopPlayers,
    StageClass,
} from "src/store/models";
import { endpointsRoot } from "src/core/endpoints";

const TournamentBracketSocket = () => {
    const { authStore, tournamentsStore } = useStores();
    const [initSocket, socket, disconnectSocket] = useSocket({
        token: authStore.getAuthToken(),
    });
    const tournamentBracketId = tournamentsStore.tournamentBracket?.id;

    useEffect(() => {
        if (!socket) return;
        socket.on(
            "tournament:player:join",
            (data: { player: ITopPlayers; game: IStageGame }) => {
           
            }
        );
        socket.on(
            "tournament:status",
            (data: {
                status: TournamentStatus;
                winner: ITopPlayers | null;
            }) => {
                
            }
        );
        socket.on(
            "tournament:game:finished",
            (data: { stage: number; gameId: string; winner: string }) => {
                console.log("tournament:game:finished");
                tournamentsStore.getCurrentTournament(tournamentBracketId!);
            }
        );
        socket.on("tournament:game:started", (data: StageClass) => {
            console.log("tournament:game:started");
            tournamentsStore.updateTournamentScore(data);
        });
        socket.on("tournament:prizePlace:game:confirmed", () => {
            console.log("tournament:game:started Brkt");
            tournamentsStore.getCurrentTournament(tournamentBracketId!);
        });
    }, [socket]);

    const connectToSocket = async () => {
        try {
            await axios.get(
                `${endpointsRoot}/api/v2/tournament/${tournamentBracketId}`
            );
            initSocket(`${endpointsRoot}/tournament/${tournamentBracketId}`);
        } catch (e) {}
    };

    useEffect(() => {
        if (!authStore.isAuthorized || !tournamentBracketId) {
            disconnectSocket();
            return;
        }
        if (authStore.isAuthorized && tournamentBracketId) {
            connectToSocket();
        }
    }, [authStore.isAuthorized, tournamentBracketId]);

    return null;
};

export default observer(TournamentBracketSocket);
