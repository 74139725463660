import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import {
    GameModeType,
    GameStatus,
    GameType,
    UserBalanceType,
} from "src/store/models";
import blurOnHover from "src/pages/lobbyNew/assets/backgrounds/backgroundBlurWild.svg";

const DesktopNav = ({ styles, isLanding }) => {
    const {
        header: { cashBattle, lobbyFreePlay, tournaments, allSlots, allDealers, Soon },
    } = useLocale();
    const { generalStore, tournamentsStore, lobbyStore } = useStores();
    const {
        diceChessLobby,
        lobbyFreePlay: lobbyFreePlayPath,
        tournaments: tournamentPath,
        algaltente,
        liveDealer: liveDealerPath,
    } = paths;
    const currentLocation = useLocation();
    const activeLobbyGames = lobbyStore.getActiveGamesDot();

    const showReturnToTournament = tournamentsStore.hasActiveGames;

    // const isCashBattle = useMemo(() => {
    //     if (activeLobbyGames) {
    //         return activeLobbyGames.filter(
    //             (game) => game.balanceType === UserBalanceType.play
    //         );
    //     }
    // }, [activeLobbyGames]);

    // const isFreePlay = useMemo(() => {
    //     if (activeLobbyGames) {
    //         return activeLobbyGames.filter(
    //             (game) => game.balanceType === UserBalanceType.coins
    //         );
    //     }
    // }, [activeLobbyGames]);

    // const isCashBattleDot = useMemo(() => {
    //     if (!isCashBattle) return false;
    //     return (
    //         activeLobbyGames &&
    //         activeLobbyGames?.length > 0 &&
    //         !!isCashBattle.length &&
    //         !currentLocation.pathname.includes(isCashBattle[0].id) &&
    //         !currentLocation.pathname.includes(paths.diceChessLobby)
    //     );
    // }, [activeLobbyGames, isCashBattle]);

    const isCashBattle = useMemo(() => {
        return !!activeLobbyGames.length;
    }, [activeLobbyGames]);

    const isCashBattleDot = useMemo(() => {
        if (!isCashBattle) return false;
        return (
            isCashBattle &&
            activeLobbyGames &&
            activeLobbyGames[0]?._id &&
            !currentLocation.pathname.includes(activeLobbyGames[0]?._id) &&
            !currentLocation.pathname.includes(paths.diceChessLobby)
        );
    }, [activeLobbyGames, isCashBattle]);

    // const isFreePlayDot = useMemo(() => {
    //     if (!isFreePlay) return false;
    //     return (
    //         activeLobbyGames &&
    //         activeLobbyGames?.length > 0 &&
    //         !!isFreePlay.length &&
    //         !currentLocation.pathname.includes(isFreePlay[0].id) &&
    //         !currentLocation.pathname.includes(paths.lobbyFreePlay)
    //     );
    // }, [activeLobbyGames, isFreePlay]);

    const isFreePlayDot = useMemo(() => {
        return false;
    }, [activeLobbyGames]);

    const isTournamentDot = useMemo(() => {
        const isNotInGamePage = tournamentsStore.myGamesList.every(
            (g) => !currentLocation.pathname.includes(g?.id)
        );
        return (
            showReturnToTournament &&
            !isNotInGamePage &&
            !currentLocation.pathname.includes(paths.tournaments)
        );
    }, [showReturnToTournament, tournamentsStore.myGamesList]);

    const links: React.ReactNode[] = [];
    const isHalyk = generalStore.isHalyk;

    if (generalStore.gameType === GameType.dicechess && !isLanding) {
        if (!isHalyk) {
            links.push(
                <Box
                    key="cashBattle"
                    component="div"
                    className={cn(styles.navMenuItemBox, {
                        gameInfoDot: isCashBattleDot,
                    })}
                >
                    <NavLink
                        to={diceChessLobby}
                        className={cn(styles.navMenuItem, {})}
                        activeStyle={{ color: "#fff" }}
                    >
                        {cashBattle("Cash Battle")}
                        <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                    </NavLink>
                    {isCashBattleDot && (
                        <div className={cn(styles.gameInfoDot, {})}></div>
                    )}
                </Box>,
                <Box
                    key="tournaments"
                    component="div"
                    className={cn(styles.navMenuItemBox, {})}
                >
                    <span className={cn(styles.navMenuItem, styles.disabledText)}>
                        {tournaments("Tournaments")} ({Soon("soon")})
                    </span>
                    {/*<img className={styles.blurOnHover} src={blurOnHover} alt="" />*/}
                    {isTournamentDot && (
                        <div className={cn(styles.gameInfoDot, {})}></div>
                    )}
                </Box>
                //<Box
                //    key="tournaments"
                //    component="div"
                //    className={cn(styles.navMenuItemBox, {})}
                //>
                //    <NavLink
                //        to={tournamentPath}
                //        className={cn(styles.navMenuItem, {})}
                //        activeStyle={{ color: "#fff" }}
                //    >
                //        {tournaments("Tournaments")}
                //        <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                //    </NavLink>
                //    {isTournamentDot && (
                //        <div className={cn(styles.gameInfoDot, {})}></div>
                //    )}
                //</Box>
            );
        }
    } else if (generalStore.gameType === GameType.slots) {
        links.push(
            <Box component="div" className={cn(styles.navMenuItemBox, {})}>
                <NavLink
                    to={algaltente}
                    className={cn(styles.navMenuItem, {})}
                    activeStyle={{ color: "#fff" }}
                >
                    {allSlots("All slots")}
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                </NavLink>
            </Box>
        );
    } else if (generalStore.gameType === GameType.liveDealer) {
        links.push(
            <Box component="div" className={cn(styles.navMenuItemBox, {})}>
                <NavLink
                    to={liveDealerPath}
                    className={cn(styles.navMenuItem, {})}
                    activeStyle={{ color: "#fff" }}
                >
                    {allDealers("All dealers")}
                    <img className={styles.blurOnHover} src={blurOnHover} alt="" />
                </NavLink>
            </Box>
        );
    }

    return <div className={styles.navMenu}>{links}</div>;
};

export default observer(DesktopNav);
