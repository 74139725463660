import React, { useMemo, useEffect, memo, useCallback, useState } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import cn from "classnames";
import { observer } from "mobx-react";

import { IPiecesMap } from "../../types";
import useStores from "../../../../hooks/useStores";
import iOSDetect from "../../../../utils/iOsDetect";
import { usePlayer } from "../../../../sounds/use-player";

import DragPieceComponent from "./DragPieceComponent";
import { useStyles } from "./styles";
import { ColorVariant } from "src/store/models";

interface Props {
    i: number;
    mobileHover: boolean;
    square: string;
    piece: string;
    pieces: IPiecesMap;
    appearanceTheme: string;
    orientation: string;
    //isAllowDrag: boolean;
    highlight: boolean;
    onSquareClick?: (target: string, index: number) => void;
    onDrop?: (
        t: { from: string; to: string },
        strictAvailableMoves: number | null
    ) => void;
    startDiceElement: HTMLElement | null;
    finalDiceElement: any;
    unitedStartFinishFigure: string[] | null;
    animationTime: number;
}

interface ComponentProps {
    isAllowDrag: boolean;
    highlight: boolean;
    appearanceTheme: string;
    orientation: string;
}

const SquareWithFigure = (props: Props & ComponentProps) => {
    const { gameStore } = useStores();
    const actionDropMove = gameStore.gameState.actionDropMove;

    const {
        i,
        highlight,
        onSquareClick,
        mobileHover,
        square,
        piece,
        pieces,
        onDrop,
        appearanceTheme,
        orientation,
        startDiceElement,
        finalDiceElement,
        unitedStartFinishFigure,
        animationTime,
    } = props;

    const lastSuccessMove = unitedStartFinishFigure;

    const player = usePlayer();

    const styles = useStyles({ appearanceTheme });

    const calcCurrentMoves = (
        highlight: boolean | undefined,
        piece: string,
        orientation: string
    ) => {
        if (highlight) {
            if (orientation === ColorVariant.white) {
                return piece.toUpperCase() === piece ? "me" : "opponent";
            }
            if (orientation === ColorVariant.black) {
                return piece.toLowerCase() === piece ? "me" : "opponent";
            }

            return `COLOR_${orientation}_${piece}`;
        }
    };

    const strictAvailableMoves = useMemo(() => {
        return calcCurrentMoves(highlight, piece, orientation) === "me"
            ? i
            : null;
    }, [highlight, piece, orientation, i]);

    const [{ isOver }, drop] = useDrop({
        accept: [
            "wp",
            "wr",
            "wn",
            "wb",
            "wq",
            "wk",
            "bp",
            "br",
            "bn",
            "bb",
            "bq",
            "bk",
        ],
        drop: (props) => {
            onDrop &&
                onDrop(
                    //@ts-ignore
                    { from: props.from, to: square },
                    strictAvailableMoves
                );
            // iOSDetect() && player("pieceSoundPlay");
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const getPos = useMemo(() => {
        //check second click + move from store include target id + start elem include start cell
        if (
            finalDiceElement &&
            lastSuccessMove &&
            finalDiceElement?.id.includes(lastSuccessMove[1]) &&
            startDiceElement?.id.includes(square)
        ) {
            //get only translate(xxx%, y%%%) exclude scale and other params
            //@ts-ignore
            const finalDicePosSpread = [
                ...finalDiceElement.style.transform.match(/\d+/g),
            ];
            gameStore.gameState.moveSound && player("pieceSoundPlay");
            return `translate(${finalDicePosSpread[0]}%, ${finalDicePosSpread[1]}%)`;
        }
        return `translate(${(i % 8) * 100}%,${Math.trunc(i / 8) * 100}%)`;
    }, [finalDiceElement]);

    const getAnimation = useMemo(() => {
        return actionDropMove && actionDropMove.length
            ? "all ease-in 0s"
            : `all ease-in ${animationTime}ms`;
    }, [actionDropMove]);

    // const getAnimation = useMemo(() => {
    //     return `all ease-in ${animationTime}ms`;
    // }, [animationTime]);

    //1. Нажимаем на свою фигуру
    //2. Если есть доступный ход, то вражеская фиргура получает статус на g7_N_Oponnent, (мы на g8_me)
    //3. Сбиваем вражескую фигуру и получаем - мы на g7 подсветки нет
    //4. Логика анимации:
    // 4.1 Если killed pieces содержит фигуру N и Статус клетки поменялся с g7_N_Oponent на g7, то
    //У нас есть начальное положение фигуры - square и понимание, цвета фигуры - square

    return (
        <div
            ref={drop}
            id={`gamePiece-square-${square}`}
            className={cn(
                styles.noselect,
                styles.square,
                isOver && highlight
                    ? mobileHover
                        ? styles.hoverMobile
                        : styles.hoverDesktop
                    : undefined,
                calcCurrentMoves(highlight, piece, orientation),
                piece.toLowerCase()
            )}
            style={{
                transition: getAnimation,
                transform: getPos,
            }}
            onMouseDown={() => {
                onSquareClick && onSquareClick(square, i);
            }}
            onTouchStart={() => {
                onSquareClick && onSquareClick(square, i);
            }}
            onTouchMove={() => {
                onSquareClick && onSquareClick(square, i);
            }}
        >
            <DragPieceComponent
                pieces={pieces}
                piece={piece}
                square={square}
                i={i}
                isAllowDrag={props.isAllowDrag}
                orientation={orientation}
                getTargetHighLight={() =>
                    calcCurrentMoves(highlight, piece, orientation)
                }
            />
        </div>
    );
};

const SquareWithFigureWrapper = (props: Props) => {
    const isAllowDrag = true;

    return (
        <SquareWithFigure
            {...props}
            isAllowDrag={Boolean(isAllowDrag)}
            //highlight={squareStyles[props.square]}
            //onSquareClick={onSquareClick}
            //onDrop={onDrop}
        />
    );
};

export default observer(SquareWithFigureWrapper);
