import { GameTime, GameTimeValues } from "src/store/lobbyStore";
import { PaymentType } from "src/service/api/cashbox/requestResponses";
import { ETournamentType } from "src/entities/tournament";
import { Nullable } from "src/shared/types";

import {
  ChessMoveHistoryItem,
  IRematchInfo,
  ERolledFigures,
} from "./gameStore/types";

export enum PopupFilterType {
  time = "time",
  match = "match",
  bet = "bet",
  gameMode = "gameMode",
}

// export enum GameStatus {
//     AWAITING = "awaiting",
//     NOT_READY = "not_ready",
//     IN_PROGRESS = "in_progress",
//     FINISHED = "finished",
//     ABOUT_TO_START = "about_to_start",
// }
//====
export enum GameStatus {
  IN_PROGRESS = 1,
  AWAITING = 2,
  FINISHED = 4,
  NOT_READY = "not_ready",
  ABOUT_TO_START = "about_to_start",
}

export enum EGameRematchStatus {
  NOT_INIT = 0,
  SHOW_MODAL = 1,
  WAITING_FOR_ANSWER = 2,
  DECLINED = 3,
  ACCEPTED = 4,
}

export enum TournamentsModal {
  PUSH_TO_GAME = "pushToGame",
  PUSH_TO_MATCH_GAME = "pushToMatchGame",
  CLOSE_OR_PLAY = "closeOrPlay",
  THIRD_GAME = "prepareThirdOrFourth",
}

export enum GameChatType {
  DESKTOP = "desktopType",
  MOBILE = "mobileType",
}

export enum ScreenType {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
  multitable = "multitable",
}

export enum GameChatToggleStatus {
  open = "open",
  hide = "hide",
}

export enum ColorVariant {
  white = "w",
  black = "b",
  none = "",
}

export enum PiecesAnimMoveAction {
  actionClickMove = "actionClickMove",
  actionDropMove = "actionDropMove",
  each = "each",
}

export interface IViewers {
  _id: string;
  avatar: string;
  email: string;
  isSystemAvatar: boolean;
  nickname: string;
  rank: number;
  rankFull: {
    end: number
    name?: RankType
    rakeBack: number
    rank: number
    start: number
  };
  rating: number
}

export interface IGameSettingsGame {
  bet: number;
  doubling: boolean;
  fen: string;
  private: boolean;
  privateChat: boolean;
  rake: number;
  randomColor: boolean;
  rounds: number;
  timeControl: { time: number; increment: number };
  increment: number;
  time: number;
  undo: boolean;
}

export interface IPlayerBase {
  avatar: string;
  color: ColorVariant;
  id: string;
  name: string;
  nickname?: string;
  rating: number;
  score: number;
  winInGames: number;
  isSystemAvatar?: boolean | undefined;
  _id?: string;
}
export interface IPlayerOpponent extends IPlayerBase {
  rank?: string | RankType;
}

export interface IPlayer extends IPlayerBase {
  rank?: { title: RankType };
  status?: string;
  pathCompressed?: string;
}

export interface GameResultData {
  winner: ColorVariant;
  cause: GameFinishReason;
}
// TODO: fix typing, game does not has "jackpot" type
export enum GameModeType {
  REGULAR = "regular",
  TOURNAMENT = "tournament",
  JACKPOT = "jackpot",
}
//====

export enum GameModeTypeNew {
  REGULAR = 1,
  MATCH = 2,
  TOURNAMENT = 3,
  FREE = 4,
}

export interface ITimeControl {
  time: number;
  increment: number;
}
export interface IGame {
  balanceType: UserBalanceType;
  bet: number;
  created: string;
  doubling: {
    multiplier: number;
    aggressor: ColorVariant;
    accepted: boolean;
  };
  events: { event: string; color: ColorVariant; data: any }[];
  fen: string;
  history: Array<any>;
  id: string;
  players: [IPlayer] | [IPlayer, IPlayer];
  private: boolean;
  privateChat: boolean;
  rematchInfo: IRematchInfo;
  settings: IGameSettingsGame;
  status: GameStatus;
  // time: GameTime;
  time: GameTimeValues;
  timeControl: ITimeControl;
  timestamp: number;
  tournament: {
    gameId: string;
    id: string;
    stage: number;
    startDate: string;
    title: string;
    type: string;
    gameSeries: number;
  } | null;
  type: GameModeType;
  result: GameResultData | null;
  undo: boolean;
  willBeDeletedIn: number;
  challenged?: {
    id: string;
    nickname: string;
    avatar: string;
    isSystemAvatar: boolean;
  } | null;
  previousGameId?: string;
  participating?: boolean | undefined;
  rounds: number;
  hideInFilter?: any;
}
//==========

export interface IsocketGameData {
  _id: string;
  activeFigures: string[];
  availableMoves: string[];
  bank: number;
  coinStatus: number;
  coinValues: any[]; // You may want to specify the type of `coinValues`
  curMove: string;
  curChessMove: string;
  currentPlayerCanX2: boolean;
  extra: Record<string, any>; // You may want to specify the structure of `extra`
  fen: string;
  fenType: number;
  free: boolean;
  gameType: number;
  gamesLeft: number;
  history: any[]; // You may want to specify the type of `history`

  matchHistory: any[]; // You may want to specify the type of `matchHistory`

  messages: any[]; // You may want to specify the type of `messages`
  move: number;
  moves: string[];
  movesHistory: any[];
  players: any[]; // You may want to specify the type of `players`
  relativeId: string | null;
  rematchStatus: EGameRematchStatus;
  rollFigures: [ERolledFigures, ERolledFigures, ERolledFigures];
  rolling: number;
  startedAt: string;
  state: number;
  time: number;
  timeLeft: number[];
  timers: number[];
  tournament: string | null;
  type: number;
  viewers: IViewers[]; // You may want to specify the type of `viewers`
  gameDieTime: any;
  matchId: number;
  matchId2: number;
  rematchStartedBy?: string;
  nextMatch?: string;
  matchCount: number;
  rematchEnd?: string;
  winner?: string;
}
export interface IGameNew {
  // availableMoves: any[];
  // extra: object;
  // fen: string;
  // fenType: number;
  // players: string[];
  // relativeId: null | string;
  // startedAt: string;
  // state: number;
  // timeLeft: number[];
  // type: number;
  // _id: string;

  _id: string;
  availableMoves: any[];
  curMove: string;
  curChessMove: string;
  bank: number;
  coinStatus: number;
  coinValues: any[];
  currentPlayerCanX2: boolean;
  extra: { [key: string]: any };
  fen: string;
  fenType: number;
  free: boolean;
  gameDieTime: string;
  gameType: number;
  gamesLeft: number;
  history: any[];
  matchHistory: any[];
  messages: any[];
  move: number;
  matchId: number;
  matchId2: number;
  matchCount: number;
  moves: any[];
  movesHistory: ChessMoveHistoryItem[];
  players: {
    _id: string;
    agreement: boolean;
    blockReason: string | null;
    email: string;
    avatar: string;
    isAdmin: boolean;
    isBot: boolean;
    isSupport: boolean;
    isSystemAvatar: boolean;
    nickname: string;
    online: boolean;
    password: string;
    referralProgramId: string | null;
    userType: string;
  }[];
  relativeId: string | null;
  rolling: number;
  startedAt: string;
  state: number;
  timeLeft: number[];
  timers: number[];
  tournament: any | null;
  type: GameModeTypeNew;
  nextMatch?: string;
  viewers: IViewers[];
  winner?: string;
}

export interface IGameUpdate {
  id: string;
  players: [IPlayer] | [IPlayer, IPlayer];
  status: GameStatus;
  created: string;
  willBeDeletedIn: number;
  timestamp: number;
  participating: boolean;
  previousGameId: string;
}
export interface IPlayerWinner {
  _id: string;
  avatar: string;
  isSystemAvatar: boolean;
  nickname: string;
  rating: number;
  stats: { wonGames: number; _id: string };
  user: string;
}

export interface IFinishedTournament {
  tournament: {
    balanceType: UserBalanceType;
    buyIn: number;
    buyInWithRake: number;
    color: string;
    created: string;
    currentStage: number;
    description: string;
    gameSeries: number;
    id: string;
    maxPlayers: number;
    playersInfo: [IPlayerWinner] | [IPlayerWinner, IPlayerWinner];
    prize: number;
    prizePlace: null | number;
    prizes: {
      amount: number;
      place: number;
      rankPoint: number;
      range?: number[] | undefined;
    }[];
    prizeWinners: {
      place: number;
      user: string;
      range?: number[] | undefined;
    }[];
    rake: number;
    stages: {
      gameSeries: number;
      games: [IStageGame];
      stage: number;
      startDate: string;
      status: string;
    };
    startDate: string;
    status: string;
    timeControl: {
      time: number;
      increment: number;
    };
    title: string;
    winner: IPlayerWinner;
  };
}

interface IGameSettings {
  confirmDoubling: boolean;
  invertCapturedFigures: boolean;
  pieceSound: boolean;
  diceSound: boolean;
  sound: boolean;
  hightlightMoves: boolean;
  autoDice: boolean;
  digitDice: boolean;
  animation: boolean;
  language: ELanguages;
  background: string;
  diceColor: string;
  sideBarIsOpen: boolean;
  dicechessBoardRightBlockMode: keyof typeof EGameChatTab;
}

interface IGameSettingsNew {
  animation: boolean;
  autoDice: boolean;
  background: string;
  classicDice: boolean;
  confirmDoubling: boolean;
  diceColor: string;
  diceSound: boolean;
  dicechessBoardRightBlockMode: string;
  digitDice: boolean;
  hightlightMoves: boolean;
  invertCapturedFigures: boolean;
  isChatBlocked: boolean;
  isSideBarOpen: boolean;
  language: ELanguages;
  pieceSound: boolean;
  sound: boolean;
  sideBarIsOpen: boolean;
}

export interface IPrizeTournamentData {
  id: string;
  title: string;
  position: any;
  prize: {
    coin: {
      amount: number;
      place: number | null;
      range: any[] | any;
    };
    rankPoint: number;
  };
}

interface IGameStats {
  profit: number;
  games: {
    ranked: number;
    standoff: number;
    lost: number;
    won: number;
    played: number;
  };
}

export interface IGameStatsNew {
  games: {
    lost: number;
    played: number;
    ranked: number;
    standoff: number;
    won: number;
    //Тест
    wonClassic: number;
    won960: number;
    lostClassic: number;
    lost960: number;
    tournament_won: number;
    moves: number;
    streak: number;
    //
  };
  rating: number;
  rate: {
    nextRank: any;
    rank: any;
    winStreak: number;
  };
  rateFull: {
    rank: number;
    start: number;
    end: number;
    name: RankType;
    rakeBack: number;
  };
  tournaments: {
    lostGames: number;
    participated: number;
    playedGames: number;
    won: number;
    wonGames: number;
  };
}

export enum RankType {
  junior1 = "Junior",
  junior2 = "Junior 2",
  junior3 = "Junior 3",

  pawn1 = "Pawn",
  pawn2 = "Pawn 2",
  pawn3 = "Pawn 3",

  knight1 = "Knight",
  knight2 = "Knight 2",
  knight3 = "Knight 3",

  bishop1 = "Bishop",
  bishop2 = "Bishop 2",
  bishop3 = "Bishop 3",

  rook1 = "Castle",
  rook2 = "Castle 2",
  rook3 = "Castle 3",

  queen1 = "Queen",
  queen2 = "Queen 2",
  queen3 = "Queen 3",

  king1 = "King",
  king2 = "King 2",
  king3 = "King 3",

  maverick1 = "Maverick",
  maverick2 = "Maverick 2",
  maverick3 = "Maverick 3",

  expert1 = "Expert",
  expert2 = "Expert 2",
  expert3 = "Expert 3",

  virtuoso1 = "Virtuoso",
  virtuoso2 = "Virtuoso 2",
  virtuoso3 = "Virtuoso 3",

  pro1 = "Pro",
  pro2 = "Pro 2",
  pro3 = "Pro 3",

  maestro1 = "Maestro",
  maestro2 = "Maestro 2",
  maestro3 = "Maestro 3",

  gm1 = "Grand master",
  gm2 = "Grand master 2",
  gm3 = "Grand master 3",

  atlant1 = "Atlant",
  atlant2 = "Atlant 2",
  atlant3 = "Atlant 3",

  reptilian1 = "Reptilian",
  reptilian2 = "Reptilian 2",
  reptilian3 = "Reptilian 3",

  kairos1 = "Kairos",
  kairos2 = "Kairos 2",
  kairos3 = "Kairos 3",

  caissashand1 = "Caissa's Hand",
  caissashand2 = "Caissa's Hand 2",
  caissashand3 = "Caissa's Hand 3",

  none = "none",
}

export interface IRank {
  _id: string;
  title: RankType;
  threshold: number;
  rakeBack: number;
  bonus: number;
  deleted: boolean;
}

interface IRankFull {
  rank: number;
  start: number;
  end: number;
  name: string;
  rakeBack: number;
}

export enum UserBalanceType {
  coins = "coins",
  play = "play",
  referralBonus = "referralBonus",
  free = "free",
}

export enum UserBalanceTypeNew {
  play = 1,
  coins = 2,
  referralBonus = 3,
}

export enum ChessboardType {
  desktopGame = "desktopGame",
  desktopView = "desktopView",
  mobileGame = "mobileGame",
  mobileView = "mobileView",
}

export interface IUserBalance {
  rakeBack: number;
  play: {
    reserved: number;
    available: number;
  };
  coins: {
    reserved: number;
    available: number;
  };
  referralBonus: {
    reserved: number;
    available: number;
  };
  hidden: boolean;
}

export interface IUserBalanceNew {
  bonus: number;
  coins: number;
  hidden: boolean;
  play: number;
  rakeBack: number;
  referralRevenueShare: number;
}

export interface IBlockedSettings {
  isChatBlocked: boolean;
  isGamePlayBlocked: boolean;
  user: string;
}

export enum GameTypes {
  dicechess = "dicechess",
  jackpoker = "jackpoker",
  sports = "sports",
  slots = "slots",
  liveDealer = "liveDealer",
}

export interface IUser {
  _id: string;
  email: string;
  nickname: string;
  hasRefProgram: boolean;
  gameSettings: IGameSettings;
  stats: IGame;
  rating: {
    elo: number;
    cashBattle: number;
  };
  online: boolean;
  balance: IUserBalance;
  nicknameChangedAt?: string | undefined;
  registered: string;
  receiveEmails: boolean;
  rank: IRank;
  rankFull: IRankFull;
  nextRank: IRank;
  rankIndex: number;
  isSystemAvatar: boolean;
  ranks: IRank[];
  avatar?: string;
  status?: string;
  pathCompressed?: string;
  verificationInfo: {
    verified: boolean;
    hasPhoto: boolean;
    hasDocuments: boolean;
  };
  notification: {
    lastViewedDate: string;
    numberOfUnseenNotifications: number;
  };
  isAggregator: boolean;
  token?: string;
  blockedSections: GameTypes[];
}

export interface IUserNew {
  _id: string;
  agreement: boolean;
  email: string;
  externalSettings: {
    favoriteExternalGames: [];
  };
  nickname: string;
  hasRefProgram: boolean;
  settings: IGameSettingsNew;
  stats: IGameStatsNew;
  rating: {
    elo: number;
    cashBattle: number;
  };
  balance: IUserBalanceNew;
  nicknameChangedAt?: string | undefined;
  registered: string;
  receiveEmails: boolean;
  rank: IRank;
  nextRank: IRank;
  rankIndex: number;
  isSystemAvatar: boolean;
  ranks: IRank[];
  avatar?: string;
  status?: string;
  pathCompressed?: string;
  verificationInfo: {
    verified: boolean;
    hasPhoto: boolean;
    hasDocuments: boolean;
  };
  notification: {
    lastViewedDate: string;
    numberOfUnseenNotifications: number;
  };
  isAggregator: boolean;
  token?: string;
  blockedSections: GameTypes[];
  password: any;
  deleted: any;
  blocked: any;
  blockReason: any;
  isAdmin: any;
  isSupport: any;
  isBot: any;
  referralProgramId: {};
  online: any;
  userType: any;
  lastViewedDate: any;
  securityStatus: any;
  comments: any[];
  extendedLogging: any;
  verification: {
    hasDocuments: boolean;
    hasPhoto: boolean;
    verified: boolean;
  };
}

export enum OnlineStatus {
  online = "online",
  offline = "offline",
}

export interface ILocaleItem {
  label: string;
  defaultValue: string;
}

export enum GameFinishReason {
  TIMEOUT = "timeout",
  CAPTURE = "capture",
  RESIGN = "resign",
  INACTIVITY = "inactivity",
  STANDOFF = "standoff",
  BASEMESSAGE = "baseMessage",
}

export enum FriendTypeEnum {
  friend = "friend",
  outgoing = "outgoing_request",
  incoming = "incoming_request",
}

export enum EGameFen {
  FEN_NORMAL = 1,
  FEN_960 = 2,
}

export interface IFriend {
  user: IUser;
  type: FriendTypeEnum;
  avatar: string;
  name: string;
  id: string;
  wins: {
    [EGameFen.FEN_NORMAL]: number;
    [EGameFen.FEN_960]: number;
  };
  losses: {
    [EGameFen.FEN_NORMAL]: number;
    [EGameFen.FEN_960]: number;
  };
  game: {
    id: string;
    status: GameStatus;
  };
  rating: number;
  deleted: boolean;
  online: boolean;
  isPlaying: boolean;
  isWaiting: boolean; 
  losesStreak: number;
  winStreak: number;
  maxPotLose: number;
  maxPotWin: number;
  isSystemAvatar?: boolean;
  rematchAwait?: boolean;
  joinAwait?: boolean;
  pathCompressed?: boolean;
  rank?: { title: RankType };
}

export interface IRatingS {
  elo: number;
  cashBattle: number;
}

export interface ISearchedUser {
  avatar: string;
  name: string;
  id: string;
  online: boolean;
  rank?: { title: RankType };
  rating: IRatingS;
  isSystemAvatar?: boolean;
  pathCompressed?: string;
}

export interface ISearchUsersResponse {
  users: IUser[];
}

export enum GameMode {
  AGGREGATOR = "aggregator",
  DICECHESS = "dicechess",
}

export enum GameType {
  jackpoker = "jackpoker",
  dicechess = "dicechess",
  slots = "slots",
  sports = "sports",
  liveDealer = "liveDealer",
}

export interface SoundFilesType {
  diceSoundPlay: string;

  pieceSoundPlay: string;
  joinSoundPlay: string;
  timeoutSoundPlay: string;
  wonSoundPlay: string;
  lostSoundPlay: string;
  drawOfferSoundPlay: string;
  rematchOfferSoundPlay: string;
  newGameOfferSoundPlay: string;
  // NEW SOUNDS
  bidConfirmSoundPlay: string;
  bidRefuseSoundPlay: string;
  buttonSoundPlay: string;
  cashboxSoundPlay: string;
  diceButtonV2SoundPlay?: string;
  diceConfirmSoundPlay: string;
  figureChooseSoundPlay: string;
  figureEatSoundPlay: string;
  forfeitSoundPlay: string;
  gameCreateSoundPlay?: string;
  musicChessOSTDemoSoundPlay?: string;
  rematchSoundPlay: string;
  roundDefeatSoundPlay: string;
  roundStartSoundPlay: string;
  roundTimeDefeatSoundPlay: string;
  settingsSoundPlay: string;
  diceSpinningSoundPlay: string;
  gameCreate: string;
  modalSound: string;
  raise: string;
}

export interface IMyGame {
  id: string;
  bet: number;
  timeControl: {
    time: number;
    increment: number;
  };
  user: {
    id: string;
  };
  opponent: {
    id: string;
    nickname: string;
    avatar: string;
    rank: {
      title: RankType;
    };
    isSystemAvatar: boolean;
    pathCompressed?: string;
  };
  created: string;
  winner: { id: string } | null;
  balanceType: UserBalanceType;
  rounds: number;
}

export enum TournamentStatus {
  REGISTERING = "registering",
  FINISHED = "finished",
  COMPLETED = "completed",
  RUNNING = "running",
  CANCELLED = "cancelled",
}

export interface IPrizes {
  amount: number;
  place: number;
}

// TODO: fix this type, IGame and ITournament got mixed
export interface ITournaments {
  balanceType: string;
  bet: number;
  buyIn: number;
  buyInWithRake: number;
  color: string;
  created: string;
  currentStage: number;
  doubling: boolean;
  fen: string;
  jackpot: {
    fromRank: string;
  } | null;
  history: Array<any>;
  id: string;
  description: string;
  maxPlayers: number;
  participating: boolean;
  playersInfo: ITopPlayers[] | [];
  players: number;
  prize: number;
  private: boolean;
  privateChat: boolean;
  rematchInfo: { b: any; w: any };
  rounds: number;
  time: string;
  prizes: IPrizes[];
  rake: number;
  status: TournamentStatus | string;
  startDate: string;
  settings: {
    isPrivate: boolean;
  };
  timeControl: {
    increment: number;
    time: number;
  };
  timestamp: number;
  title: string;
  tournament: {
    gameId: string;
    id: string;
    stage: number;
    startDate: string;
    title: string;
    type: string;
    gameSeries: number;
    startAt?: any;
  };
  type: ETournamentType;
  undo: boolean;
  willBeDeletedIn: number;
  challenged?: {
    id: string;
    nickname: string;
    avatar: string;
    isSystemAvatar: boolean;
  } | null;
  winner?: ITopPlayers | null;
}

export interface ITournamentModalInfo {
  balanceType: string;
  buyIn: number;
  buyInWithRake: number;
  color: string;
  created: string;
  currentStage: number;
  description: string;
  jackpot: {
    fromRank: string;
  } | null;
  gameSeries: number;
  id: string;
  maxPlayers: number;
  participating: boolean;
  playersInfo: ITopPlayers[] | [];
  prize: number;
  prizePlace: null;
  prizes: {
    amount: number;
    place: number;
    range?: any;
    rankPoint: number;
  }[];
  rake: number;
  settings: {
    isPrivate: boolean;
    registrationStartDate: string;
  };
  stages: any;
  startDate: string;
  status: TournamentStatus | string;
  stopRegistration: boolean;
  timeControl: {
    increment: number;
    time: number;
  };
  title: string;
  type: GameModeType;
  winner?: ITopPlayers | null;
}

export enum TimePreviewExistPosition {
  lobby = "lobby",
  tournamentModal = "tournamentModal",
}

export enum PlayerInfoExistPosition {
  inGame = "inGame",
  inProgressPreview = "inProgressPreview",
  inProgressPreviewFull = "inProgressPreviewFull",
  inLobbyPreview = "inLobbyPreview",
  inProgressTournamentView = "inProgressTournamentView",
  inHistoryView = "inHistoryView",
}

export enum ChatExistPosition {
  inGame = "inGame",
  inProgressPreview = "inProgressPreview",
  inProgressPreviewFull = "inProgressPreviewFull",
  inProgressTournamentView = "inProgressTournamentView",
  inHistoryView = "inHistoryView",
}

export interface ITopPlayers {
  avatar: string;
  isSystemAvatar: boolean;
  nickname: string;
  rating: number;
  id: string;
  stats: {
    _id: string;
    wonGames: number;
  };
  user: string;
  rank: { title: RankType };
  _id?: string | undefined;
  pathCompressed?: string | undefined;
  score: number;
}
//====
export interface ITopPlayersNew {
  _id: string;
  avatar: string | null;
  email: string;
  isSystemAvatar: boolean;
  nickname: string;
  rank: number;
  rating: number;
}

export interface IRequestTopPlayers extends ITopPlayers {
  users: ITopPlayers[];
  place: number;
}

export interface ITournamentBracketPlayer {
  id: string;
  avatar: string;
  isSystemAvatar: boolean;
  nickname: string;
  rating: number;
  stats: {
    _id: string;
    wonGames?: string | undefined;
  };
  user: string;
  _id?: string | undefined;
  pathCompressed?: string | undefined;
}

export interface IStageGame {
  gameId: string | null;
  id: string;
  matches?: {
    gameId: string;
    winner: string;
  }[];
  player1: string | null;
  player2: string | null;
  referTo: string | null;
  referPlayer?: number | null;
  score?: {
    player1: number;
    player2: number;
  };
  status: TournamentStatus | string | null;
  winner: ITopPlayers | string | null;
  startDate?: string | null;
}
export interface ITournamentBracketStages {
  gameSeries: number;
  games: Array<IStageGame>;
  stage: number;
  startDate: string;
  status: TournamentStatus | string | null;
}

export interface ITournamentBracket {
  balanceType: string;
  buyIn: number;
  buyInWithRake: number;
  color: string;
  created: string;
  currentStage: string;
  description: string;
  gameSeries: number;
  id: string;
  maxPlayers: number;
  participating: boolean;
  playersInfo: ITournamentBracketPlayer[];
  playersMap?: Record<string, ITournamentBracketPlayer>;
  prize: number;
  prizePlace: any | null;
  prizes: {
    amount: number;
    place: number;
    rankPoint: number;
    range: any | null;
  }[];
  score: {
    player1: number;
    plaeyer2: number;
  };
  rake: number;
  stages: ITournamentBracketStages;
  startDate: string;
  status: TournamentStatus | string | null;
  timeControl: {
    increment: number;
    time: number;
  };
  title: string;
  winner: ITopPlayers | null;
}

export interface StageClass {
  gameId: string;
  gameUid: string;
  score: {
    player1: number;
    player2: number;
  };
  stage: number;
  type: string;
}

export interface IParticipantsMobile extends ITopPlayers { }

export interface ISeedBracketMobile {
  date: string;
  gameId: string | null;
  id: string;
  player1: string;
  player2: string;
  referPlayer: number | null;
  referTo: string | null;
  score: {
    player1: number;
    player2: number;
  };
  status: string | null;
  teams: ITopPlayers[];
  winner: string | null;
  coupleCoords?: string | null;
  matches: any[];
}

export interface ISeedBracketDesktop {
  date: string;
  gameId: string | null;
  id: string;
  player1: string | null;
  player2: string | null;
  referPlayer: number | null;
  referTo: string | null;
  status: string | null;
  teams: {
    avatar: string | undefined;
    isSystemAvatar: boolean | undefined;
    nickname: string | undefined;
    rating: number | undefined;
    score: number;
    stats: {
      _id: string | undefined;
      wonGames?: string | undefined;
    };
    user: string | undefined;
    _id?: string | undefined;
    pathCompressed?: string | undefined;
  }[];
  winner: string | null;
  coupleCoords?: string | null;
}

export interface ISeedBracket {
  seeds: ISeedBracketDesktop[];
  title: string;
  coupleCoords?: string | null;
  isThirdPlaceGame?: number | null;
}

export enum ELanguages {
  EN = "en",
  FR = "fr",
  DE = "de",
  RU = "ru",
  KZ = "kz",
  UA = "ua",
}

export interface IPaymentMethods {
  topUp: IPaymentMethod[];
  withdrawal: IPaymentMethod[];
}

export interface IPaymentMethod {
  id: string;
  name: string;
  exchangeRate: number;
  minAmount: number;
  maxAmount: number;
  currency: string;
  iconUrl: string;
  enabled: boolean;
  form: Nullable<[{ name: string }]>;
  supportedCountries: string[];
  suggestionList: number[];
}

export interface ReferralStatsData {
  stats: ReferralStats;
  general: GeneralReferralStats;
}
export interface GeneralReferralStats {
  balance: number;
  url: string;
  rate: number;
}
export interface ReferralStats {
  games?: ReferralStatsNumbers;
  rake?: ReferralStatsNumbers;
  lost?: ReferralStatsNumbers;
  won?: ReferralStatsNumbers;
  getFromTable?: ReferralStatsNumbers;
  putOnTable?: ReferralStatsNumbers;
  revenue: ReferralStatsNumbers;
  users: ReferralStatsNumbers;
}
export interface ReferralStatsNumbers {
  today: number;
  total: number;
}

export interface ReferralUser {
  username: string;
  totalGames?: string;
  totalRake?: number;
  totalWon?: string;
  totalLost?: string;
  totalGetFromTable?: string;
  totalPutOnTable?: string;
  revenue: string;
  registered: Date;
}
export enum EModalsLabels {
  friendFightInit = "friendFightInit",
  friendFightReceive = "friendFightReceive",
  lobbyInitiatorAwaiting = "lobbyInitiatorAwaiting",
  lobbyConfirmGame = "lobbyConfirmGame",
  tournamentSecondModal = "tournamentSecondModal",
}

export interface IJackpot {
  amount: number;
  rate: number;
  createdAt: Date;
}

export interface IGameTable {
  id: string | null;
  type: GameType | null;
  state: "lobby" | "game" | null;
  isMenu: boolean;
}

export enum NotificationActionTypes {
  internal_redirect = "internal_redirect",
  external_redirect = "external_redirect",
  custom_action = "custom_action",
}

export enum NotificationCustomActionNames {
  reward_rank_progression = "reward_rank_progression",
}

export interface INotificationAction {
  tagType: "button";
  disabled: boolean;
  actionType: NotificationActionTypes;
  actionProperties: {
    actionName?: NotificationCustomActionNames;
    tagLabel?: string;
    tagLocaleProps?: Record<string, any>;
    path?: string;
    link?: string;
  };
}

export interface INotification {
  id: string;
  locale: {
    label: {
      title: string;
      description: string;
      props?: Record<string, string | number>;
    };
    title?: {
      ru: string;
      en: string;
    };
    description?: {
      ru: string;
      en: string;
    };
  };
  additionalData?: Record<string, any>;
  isRead: boolean;
  isGlobal: boolean;
  actions?: INotificationAction[];
  created: Date;
}

export enum BannerTypes {
  stream = "stream",
  new_game = "new_game",
}

export enum RedirectTypes {
  internal_redirect = "internal_redirect",
  external_redirect = "external_redirect",
}

export enum StyleTypes {
  // TODO: fix naming
  withBackground,
  withAvatar,
}

export interface IBanner {
  _id: string;
  text: string;
  type: BannerTypes;

  backgroundColor: string;
  image: {
    desktop: {
      url: string;
      filename: string;
    };
    mobile: {
      url: string;
      filename: string;
    };
  };
  button: {
    backgroundColor: string;
    borderColor: string;
  };
  styleType: StyleTypes;

  date: Date;

  redirectType: RedirectTypes;

  redirectTo: string;
}

export interface IVariable {
  name: VariableName | string;
  value: any;
}

export enum VariableName {
  pokerAvailability = "pokerAvailability",
}

export enum EGameChatTab {
  chat = "chat",
  history = "history",
  fastGame = "fastGame",
  watchers = "watchers",
}

export interface IGameChatTab {
  [key: string]: string;
}

export interface IStickerDetail {
  id: string;
  filename: string;
}

export interface IStickerRequest {
  id: string;
  pack: string;
}

export interface IStickerPackList {
  stickerPackName: string;
  stickersDetails: IStickerDetail[];
}

// ==================

//for "Classic" and 960 game
export enum GameCreateFen {
  classic = 1,
  nineHundredSixty = 2,
}

export enum GameTypeCreate {
  one = 1,
  two = 2,
}

export enum GameCreateTime {
  one = 1,
  three = 3,
  five = 5,
  ten = 10,
  twenty = 20,
}

export enum DirectionAccepts {
  one = 1,
  two = 2,
}

export interface PlayerDirectionData {
  email: string;
  isSystemAvatar: boolean;
  nickname: string;
  _id: string;
  avatar?: string;
  rank: number;
  rating: number;
  rankFull: {
    end: number;
    name: string;
    rakeBack: number;
    rank: number;
    start: number;
  };
}

export interface ILobbyInvites {
  createdAt: string;
  details: {
    type: number;
    fenType: GameCreateFen;
    gameCount: number;
    bank: number;
  };
  time: GameTimeValues;
  from: PlayerDirectionData;
  to: PlayerDirectionData;
  _id: string;
  players: [IPlayer] | [IPlayer, IPlayer];
}

export interface ILobbyInvitesAccepts {
  createdAt: string;
  details: {
    type: number;
    fenType: GameCreateFen;
    bank: number;
    gameCount: number;
  };
  direction: DirectionAccepts;
  time: GameCreateTime;
  from: PlayerDirectionData;
  to: PlayerDirectionData;
  _id: string;
  // prevId
  // time
  players: [IPlayer] | [IPlayer, IPlayer];
}

export interface ILobbyGame {
  availableMoves: any[]; // Тип данных не указан, предполагается any[]
  extra: object; // Тип данных не указан, предполагается object
  fen: string;
  fenType: number;
  players: [IPlayer] | [IPlayer, IPlayer];
  relativeId: null; // Тип данных не указан, предполагается null
  startedAt: string;
  state: number;
  time: number;
  timeLeft: number[];
  type: number;
  _id: string;
  bank: number;
  curMove: string;
  matchCount: number;
  matchId: number;
  rematchStatus: number;
  rollFigures: any[];
}

export interface ILobbyInvitesArray {
  invites: ILobbyInvites[];
}

export interface IGamesNewArray {
  games: ILobbyGame[];
}

export interface IFriendsNewArray {
  friends: unknown[];
}

export interface ITournamentNewArray {
  tournaments: unknown[];
}

export interface ITopRateUsers {
  topRateUsers: ITopPlayersNew[];
}

export interface ILobbyInvitesAcceptsArray {
  invitesAccepts: ILobbyInvitesAccepts[];
}
export interface ILobbyInvitesAcceptsNormalArray {
  invitesAcceptsNormal: ILobbyInvitesAccepts[];
}

export interface IStateFromSocket
  extends IGamesNewArray,
  ILobbyInvitesArray,
  ILobbyInvitesAcceptsArray,
  ILobbyInvitesAcceptsNormalArray,
  ITournamentNewArray,
  ITopRateUsers,
  IFriendsNewArray {
  balance: IUserBalanceNew;
}

export interface ICountry {
  id: string;
  code: string;
  name: string;
}

export interface IGetGamesParams {
  offset: number;
  limit: number;
  isPopular?: boolean;
  isFavorite?: boolean;
  isNew?: boolean;
  providerId?: string;
  query?: string;
}
