import cn from "classnames";
import { useLayoutEffect, useRef, useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { useHistory } from "react-router-dom";
import { usePlayer } from "src/sounds/use-player";

import { useLocale } from "src/providers/LocaleProvider";
import useWindowSize from "src/hooks/useWindowSize";
import useStores from "src/hooks/useStores";
import { GameChatToggleStatus, EGameChatTab } from "src/store/models";

import { useChessBoardProps } from "../hooks/useChessBoardProps";
import { MAX_XL_TABLET } from "../constants";

import { useStyles } from "./styles";

import DesktopLayout from "./layoutTypes/desktop/desktopLayout";
import MobileTabletLayout from "./layoutTypes/mobileTablet/mobileTabletLayout";

interface Props {
    //LEGACY
    diceClickRnd?: number;
    setDiceClickRnd?: any;
    rndPrefix?: number;
}

const Layout = (props: Props) => {
    const { gameStore, gamesStore, authStore } = useStores();
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;
    const isMoveSound = gameStore.gameState.moveSound;
    const winner = gameStore.currentGameNew?.winner;
    const moveHistory = gameStore.currentGameNew?.movesHistory;
    const me = authStore.currentUser?._id;
    const [tabletRatio, setRatioSize] = useState<boolean | undefined>(() => {
        // const orientation = currentWidth - currentHeight;
        if (currentWidth < MAX_XL_TABLET) true;
        else return false;
    });

    const [chatTogglerState, runChatToggler] = useState<
        GameChatToggleStatus.open | GameChatToggleStatus.hide | boolean
    >(false);
    const { width, height } = useWindowSize();
    const player = usePlayer();

    const gameContainerRef = useRef<any>(null);
    const history = useHistory();
    const {
        game: {
            multiTable: { toSoloGame },
        },
    } = useLocale();

    const { isMultiTable, isTwoGameTable, currentGame } = useChessBoardProps();

    const style = useStyles();
    const isOneActiveGame = gamesStore.gameTables.filter(
        (table) => table.state === "game"
    )?.length;

    const styleSpring = useSpring({
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
        delay: 1000,
    });

    useLayoutEffect(() => {
        if (currentWidth < 1024) setRatioSize(true);
        else setRatioSize(false);
    }, [width, height, currentWidth, currentHeight]);

    const viewMode = gameStore.isViewMode();
    const moveHistoryData = moveHistory
        ? moveHistory[moveHistory.length - 1]
        : null;

    const oppoSound =
        moveHistoryData &&
        moveHistoryData.user !== me &&
        !!moveHistoryData.move;

    useEffect(() => {
        if (!viewMode) return;
        if (gameStore.nextRound) {
            history.push(`/game/${gameStore.nextRound.gameId}`);
        }
    }, [viewMode, gameStore.nextRound]);

    useEffect(() => {
        if (oppoSound && !winner) {
            return player("pieceSoundPlay");
        }
    }, [isMoveSound, oppoSound, moveHistoryData]);

    if (!width || !height) return null;

    return (
        <animated.div
            className={cn(style.gameContainerAppearAnimate, {})}
            style={{
                ...styleSpring,
            }}
        >
            <div
                ref={gameContainerRef}
                className={cn(style.gameContainer, {
                    isMultiTable,
                    isTwoGameTable,
                })}
            >
                {tabletRatio ? (
                    <MobileTabletLayout tabletRatio={tabletRatio} />
                ) : (
                    <DesktopLayout
                        tabletRatio={tabletRatio}
                        gameContainerRef={gameContainerRef?.current}
                    />
                )}
            </div>
        </animated.div>
    );
};

export default Layout;
