import React, { useState, useEffect, useRef, useMemo } from "react";
import _get from "lodash/get";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { observer } from "mobx-react";
import { useMediaQuery } from "@mui/material";

import useStores from "src/hooks/useStores";
import { windowsSizeParams } from "src/consts/window-size-params";
import useWindowSize from "src/hooks/useWindowSize";
import { LeftLayoutPropsContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import PiecesComponent from "src/pages/game-new2/layouts/components/center/piecesComponent";
import LeftLayoutView from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/components/left";
import RightLayoutView from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/components/right";
import ChessBoardView from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/components/center/chessboard-custom";
import { WatchersStatusType } from "src/components/WatchingPlayers/components/types/watchingTypes";
import { ColorVariant, ScreenType } from "src/store/models";
import { useStyles } from "src/pages/game-new2/layouts/layoutTypes/gameChessboardWrapper/styles";

import shortColor from "src/utils/shortColor";
// import { useStyles } from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/stylesOld";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MAX_XL_TABLET,
} from "src/pages/game-new2/constants";
import ViewModeModals from "src/pages/game-new2/components/modals/index";

interface IViewChessboardProps {
    players: any;
    playersOnline: any;
    rndPrefix: number;
    diceClickRnd: number;
    elemHeight: number;
    piecesSize: number;
    mobileTabletLayoutContainer: React.RefObject<HTMLDivElement>;
}

const ViewDesktopChessboardWrapper = (props: any) => {
    const {
        rndPrefix,
        diceClickRnd,
        mobileTabletLayoutContainer,
    }: IViewChessboardProps = props;
    const { gameStore, lobbyStore, viewHistoryGameStore } = useStores();
    const history = useHistory();

    const { chatDesktopType } = useLeftLayoutContext();
    const { width, height } = useWindowSize();
    const desktopLayoutContainerRef = useRef<HTMLDivElement>(null);
    const desktopRightItemRef = useRef<HTMLDivElement>(null);
    const desktopRightItemWidth = desktopRightItemRef?.current?.offsetWidth;
    const desktopRightItemHeight = desktopRightItemRef?.current?.offsetHeight;
    const gameContainerWidth = desktopLayoutContainerRef?.current?.offsetWidth;
    const gameContainerHeight =
        desktopLayoutContainerRef?.current?.offsetHeight;

    //======= NEW
    const nextMatch = gameStore?.currentGameNew?.nextMatch;
    const game = gameStore.currentGameNew;
    const pWhitePieces = gameStore.currentGameNew?.players[0];
    const pBlackPieces = gameStore.currentGameNew?.players[1];

    const { isMultiTable } = useChessBoardProps();
    const [elemHeight, setHeight] = useState<any>();
    const [isOpen, setOpen] = useState<boolean | WatchersStatusType>(false);
    const classes = useStyles({ elemHeight });

    const killedPieces = useMemo(() => {
        return gameStore.gameState.killedPiecesNew;
    }, [gameStore.stateNew.movesHistory]);

    const getMiddleSize = <
        T extends number | undefined,
        U extends number | undefined,
        P extends number,
        C extends number,
        O extends number
    >(
        gameContainerWidth: T,
        gameContainerHeight: U,
        piecesWrapper: P,
        paddingCorrector: C,
        bottomOffset: O
    ): number | undefined => {
        if (gameContainerWidth && gameContainerHeight && height) {
            let calcWidth = gameContainerWidth - paddingCorrector * 4;
            let calcHeight =
                gameContainerHeight -
                paddingCorrector * 2 -
                (height > MAX_XL_TABLET ? bottomOffset : 0);
            let piecesSpace = piecesWrapper * 2;
            let maxSize = calcWidth / 2 - piecesSpace;
            let calcSize = Math.min(calcWidth, calcHeight) - piecesSpace;
            if (maxSize >= calcSize) return calcSize;
            else return maxSize;
        }
        return undefined;
    };

    const middleSize = useMemo(
        () =>
            getMiddleSize(
                gameContainerWidth,
                gameContainerHeight,
                60,
                16,
                windowsSizeParams.desktopAppBarHeightNew
            ),
        [gameContainerWidth, gameContainerHeight, width, height]
    );

    const changeScreenStyles = useMediaQuery(`(min-width: ${MAX_XL_TABLET}px)`);

    const isActivePlayer = (color, clock) =>
        shortColor(color) === _get(clock, "color", "unknown");

    const viewMode = gameStore.isViewMode();

    const myColor = useMemo(() => {
        if (viewMode) {
            return ColorVariant.white;
        }
        return gameStore.currentGameNew?.players[0]._id ===
            gameStore.rootStore.authStore.currentUser?._id
            ? ColorVariant.white
            : ColorVariant.black;
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore.currentGameNew,
    ]);

    const fen = gameStore.gameState.fen;

    useEffect(() => {
        if (isOpen === WatchersStatusType.hide) {
            setTimeout(() => {
                setOpen(false);
            }, 500);
        }
    }, [isOpen]);

    const getSize = <
        T extends number | undefined,
        U extends number | undefined,
        P extends number
    >(
        gameContainerWidth: T,
        gameContainerHeight: U,
        piecesWrapper: P
    ): number | undefined => {
        if (gameContainerWidth && gameContainerHeight) {
            let maxSize = gameContainerWidth / 2 - piecesWrapper * 2;
            let calcSize =
                Math.min(gameContainerWidth, gameContainerHeight) -
                piecesWrapper * 2;
            if (maxSize >= calcSize) return calcSize;
            else return maxSize;
        }
        return undefined;
    };

    const calcDiceSize = <
        W extends number | undefined,
        H extends number | undefined
    >(
        diceWrapperWidthParams: W,
        diceWrapperHeightParams: H
    ): number => {
        if (diceWrapperWidthParams && diceWrapperHeightParams) {
            //Max size - 20% of Height
            let diceHeight = diceWrapperHeightParams * 0.2;
            let calcSize = Math.min(diceWrapperWidthParams / 3, diceHeight);
            return calcSize * 0.7;
        }
        return 65;
    };

    useEffect(() => {
        let actualSize = calcDiceSize(
            desktopRightItemWidth,
            desktopRightItemHeight
        );
        gameStore.setPiecesSize(actualSize, ScreenType.desktop);
    }, [desktopRightItemWidth, desktopRightItemHeight]);

    useEffect(() => {
        if (nextMatch) {
            lobbyStore.setIsRematchGame(false);
            gameStore.resetGame();
            gameStore.clearSocket();
            viewHistoryGameStore.setGameIdNewView(nextMatch);
            history.push(`/game/${viewHistoryGameStore.gameId}`);
        }
    }, [nextMatch]);

    if (!game || !pWhitePieces || !pBlackPieces) return null;

    return (
        <>
            <div
                ref={desktopLayoutContainerRef}
                className={cn(classes.desktopLayoutContainer, { isMultiTable })}
                // style={{ gridTemplateColumns: `1fr ${minSize}px 1fr` }}
            >
                <>
                    {!isMultiTable && !props.tabletRatio && (
                        <div className={classes.desktopLeftBox}>
                            <LeftLayoutPropsContext.Provider
                                value={{
                                    chatDesktopType,
                                }}
                            >
                                <div
                                    className={cn(
                                        classes.desktopLeftRightItem,
                                        "left"
                                    )}
                                >
                                    <LeftLayoutView />
                                </div>
                            </LeftLayoutPropsContext.Provider>
                        </div>
                    )}
                    <div
                        className={cn(classes.desktopCenterBox, {
                            isMultiTable,
                        })}
                    >
                        <div
                            className={cn(
                                classes.desktopCenterPiecesWrapper,
                                "top",
                                {
                                    isMultiTable,
                                }
                            )}
                        >
                            <PiecesComponent
                                killedPieces={killedPieces[ColorVariant.black]}
                                color={ColorVariant.white}
                                desktopWidth={middleSize}
                            />
                        </div>
                        <div
                            className={cn(classes.desktopCenterSquaredWrapper, {
                                isMultiTable,
                            })}
                        >
                            <ChessBoardView
                                marginTop={0}
                                marginBottom={0}
                                border
                                fen={fen.split(" ")[0]}
                                myColor={myColor}
                                viewMode={viewMode}
                                isMultiTable={isMultiTable}
                                isMultitableСhessBoardHeight={undefined}
                            />
                        </div>
                        <div
                            className={cn(
                                classes.desktopCenterPiecesWrapper,
                                "bottom",
                                {
                                    isMultiTable,
                                }
                            )}
                        >
                            <PiecesComponent
                                killedPieces={killedPieces[ColorVariant.white]}
                                color={ColorVariant.black}
                                desktopWidth={middleSize}
                            />
                        </div>
                    </div>

                    <div className={cn(classes.desktopRightBox, {})}>
                        <div
                            ref={desktopRightItemRef}
                            className={cn(
                                classes.desktopLeftRightItem,
                                "right"
                            )}
                        >
                            <RightLayoutView />
                        </div>
                    </div>
                </>
            </div>
        </>
    );
};

export default observer(ViewDesktopChessboardWrapper);
