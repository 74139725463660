import { Box, useMediaQuery } from "@mui/material";
import cn from "classnames";

import { observer } from "mobx-react";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import _get from "lodash/get";

import PlayerInfoRedesign from "src/components/PlayerInfoRedesign";
import WatchingList from "src/components/WatchingPlayers/components/WatchingList";
import {
    WatchersStatusType,
    WatchingType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import MobilePot from "src/components/controls/bank/coinAnimation/MobilePotContainer/mobilePot";
import CoinAnimationContainer from "src/components/controls/bank/coinAnimation/coinAnimationContainer";

import GameScoreRedesign from "src/components/gameScoreRedesign";
import { windowsSizeParams } from "src/consts/window-size-params";
import useChangeTheme from "src/hooks/useChangeTheme";
import useCheckFullScreen from "src/hooks/useCheckFullScreen";
import useOrientation from "src/hooks/useOrientation";
import useStores from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";
import { getBeatCounter } from "src/utils/getBeatCounter";

import ActionAndReturn from "src/pages/game-new2/components/actionAndReturn";
import useDoublingStageStatus from "src/pages/game-new/hooks/useDoublingStageStatus";
import { usePlayer } from "src/sounds/use-player";
import {
    ChessboardType,
    ColorVariant,
    GameModeType,
    GameModeTypeNew,
    GameStatus,
    UserBalanceType,
    PlayerInfoExistPosition,
    ScreenType,
} from "src/store/models";
import shortColor from "src/utils/shortColor";
import RematchAlerts from "src/pages/game-new2/alerts/rematchAlerts";

import X2Dialogs from "src/pages/game-new2/alerts/X2Dialogs";
import DrawDialog from "src/pages/game-new2/alerts/drawDialog";
import RematchDialog from "src/pages/game-new2/alerts/rematchAlerts";
import SelectFiguresAlertWrapper from "src/pages/game-new2/alerts/select-figure-alert";
import Chessboard from "src/pages/game-new2/layouts/components/center/chessboard-custom";
import WinOrLostAlert from "src/pages/game-new2/alerts/winOrLostAlerts/";
import RiseAndCoinAnimation from "src/components/controls/bank/coinAnimation/riseAndCoinAnimation";

import {
    MobileGiveUpAlert,
    MobilePlayerThinking,
    MobileRematchDialogs,
    MobileWinOrLostAlerts,
    MobileX2Alert,
    TournamentsMatchResult,
} from "src/pages/game-new2/components/MobileAlerts";

import { DoublingStage } from "src/store/gameStore/dialogs";

import { MobileDrawDialogs } from "src/pages/game-new2/components/MobileAlerts/DrawDialogs";

import DicesWrapper from "src/pages/game-new2/components/dicesWrapper";
import RakeBackModal from "src/pages/game-new2/components/modals/RakeBackModal";

import StartMatch from "src/pages/game-new2/components/startMatch";

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_L_TABLET,
    MIN_XL_TABLET,
} from "src/pages/game-new2/constants";

import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";

import { useStyles } from "src/pages/game-new2/layouts/layoutTypes/gameChessboardWrapper/styles";
import CoinRiseComponent from "src/components/controls/bank/coinAnimation/coinRiseComponent";
import BoardV2 from "../../../../../components/BoardV2";

const MobileGameChessboardWrapper = (props) => {
    // PROPS
    const gameContainerWidth =
        props.mobileTabletLayoutContainer?.current?.offsetWidth;
    const gameContainerHeight =
        props.mobileTabletLayoutContainer?.current?.offsetHeight;
    //HOOKS
    const {
        gameStore,
        authStore,
        lobbyStore,
        tournamentsStore,
        achievementsStatStore,
    } = useStores();

    const {
        chessBoardWidth,
        chessBoardRef,
        isMultiTable,
        numberOfGames,
        isTwoGameTable,
    } = useChessBoardProps();
    const location = useLocation();
    const windowSize = useWindowSize();
    const screenStatus = useCheckFullScreen();
    const { showOpponentAcceptedDoublingDialog } = useDoublingStageStatus();
    const orientation = useOrientation();
    const soundPlayer = usePlayer();
    const changeScreenStyles = useMediaQuery(
        `(max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`
    );
    const appearanceTheme = useChangeTheme();
    const L_Tablet = useMediaQuery(`(max-width: ${MIN_L_TABLET}px)`);
    const XL_Tablet = useMediaQuery(`(max-width: ${MIN_XL_TABLET}px)`);
    const isGamePage = useRouteMatch("/game/:id");
    const refBoard = useRef<HTMLInputElement>(null);
    const diceWrapperRef = useRef<HTMLDivElement>(null);

    //MOBX STORE DATA
    const activeClock = gameStore.gameState.activeClockNew;
    const amountBank = gameStore?.currentGameNew?.bank || 0;

    // const amountBank = 100;
    const balanceType = gameStore?.currentGameNew?.gameType;
    const gameInfo = gameStore?.currentGameNew;
    const curChessMove = gameStore.currentGameNew?.curChessMove;
    const curMove = gameStore.currentGameNew?.curMove;
    const dialogsAreHide = gameStore.dialogsAreHide;
    const doubleThinking = gameStore.dialogs.oponentThinkingDialog.isOpen;
    const doublingAcceptedByOponent =
        gameStore.dialogs.doublingDialog.isOpen &&
        gameStore.dialogs.doublingDialog.stage ===
            DoublingStage.opponentAcceptedDoubling;
    const doublingDialogIsOpen =
        gameStore.dialogs.doublingDialog.isOpen &&
        gameStore.dialogs.doublingDialog.stage ===
            DoublingStage.acceptOrRejectDoubling;
    const isInitedDoublingDialog = gameStore.dialogs.doublingDialog.isOpen;
    const doublingDialoFinished = !gameStore.dialogs.doublingDialog.isOpen;
    const extra = gameStore?.currentGameNew?.extra;
    const fen = gameStore.gameState.fen;
    const firstPlayer = gameStore.currentGame?.players?.[0]?.score;
    const gameSeries = gameStore.currentGame?.tournament?.gameSeries;
    const gameStage = gameStore?.currentGame?.tournament?.stage;
    const gameType = gameStore.currentGameNew?.type;
    const isLeftBet = !!extra && !!extra.leftBet;
    const isRightBet = !!extra && !!extra.rightBet;
    const isMatchGame = gameStore.currentGame?.settings?.rounds! > 1;
    const isMultiRoundGame =
        gameStore.currentGame?.settings?.rounds! > 1 ||
        gameType === GameModeTypeNew.MATCH;
    const isShowWinOrLostDialog = gameStore.isShowWinOrLostDialog;
    const isTournamentGame = gameType === GameModeTypeNew.TOURNAMENT;
    const isVisible = authStore.isVisibleWebApp;
    const meOrWhitePlayerIndex = gameStore.isCreator;
    const myMove = gameStore.isMyMove;
    const resultData = gameStore?.initData?.over;
    const resign = gameStore?.gameState?.gameResultData?.cause;
    const secondPlayer = gameStore.currentGame?.players?.[1]?.score;
    const showWatchersBlock = gameStore.getShowWatchersBlock;
    const user = authStore.currentUser;
    const userCoinBalance = authStore.userCoinBalance;
    const userFreeCoinBalance = authStore.userFreeCoinBalance;
    const userRakeBack = authStore?.currentUser?.balance?.rakeBack;
    const watchers = gameStore.guests;
    const winner = gameStore?.currentGameNew?.winner;
    const rematchInfo = gameStore.dialogs.rematchData;
    const iAmInitiator = user?._id === rematchInfo.rematchStartedBy;

    const waitingBetApply = extra
        ? extra["waitingBetApply"] && curMove !== authStore.currentUser?._id
        : false;

    const viewMode = gameStore.isViewMode();
    const currentPlayer = gameStore.gameState.getCurrentPlayer();
    const getAllMyParticipatingGames = lobbyStore.getParticipatingGames();

    //MEMO || CONDITIONS
    const myColor = useMemo(() => {
        return gameStore.getMyColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
        gameStore?.currentGameNew?.matchId,
        gameStore?.currentGameNew?.matchId2,
    ]);

    const opponentColor = useMemo(() => {
        return gameStore.getOpponentColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
        gameStore?.currentGameNew?.matchId,
        gameStore?.currentGameNew?.matchId2,
    ]);
    const myTime = useMemo(() => {
        if (!gameStore?.rightBlockCollector.clockData.length) return 0;
        return (
            gameStore?.rightBlockCollector.clockData[meOrWhitePlayerIndex] || 0
        );
    }, [
        viewMode,
        myColor,
        gameStore.gameId,
        gameStore?.rightBlockCollector.clockData,
        activeClock,
        myMove,
    ]);

    const killedPieces = useMemo(() => {
        return gameStore.gameState.killedPiecesNew;
    }, [gameStore.stateNew.movesHistory]);

    const getCenterSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        gameContainerWidth: T,
        gameContainerHeight: U
    ): string => {
        if (gameContainerWidth && gameContainerHeight) {
            let calcSize = Math.min(gameContainerWidth, gameContainerHeight);
               //@ts-ignore
            if (gameContainerHeight - gameContainerWidth < 290) {
                  //@ts-ignore
                return `${gameContainerHeight - 290}px`;
            }

            return `${calcSize}px`;
        }
        return "calc((100dvh - 80px) / 3)";
    };

    const getTopBottomSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        gameContainerWidth: T,
        gameContainerHeight: U
    ): string | undefined => {
        if (gameContainerWidth && gameContainerHeight) {
             //@ts-ignore
            if (gameContainerHeight / 3 >= gameContainerWidth) {
                return `${gameContainerWidth}px`;
            }
        }
        return;
    };

    const calcDiceSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        diceWrapperWidthParams: T,
        diceWrapperHeightParams: U
    ): number => {
        if (diceWrapperWidthParams && diceWrapperHeightParams) {
            return (
              //@ts-ignore
                Math.min(diceWrapperWidthParams / 3, diceWrapperHeightParams) *
                0.7
            );
        }
        return 50;
    };

    const piecesBeatCounter = useMemo(() => {
        const data = getBeatCounter(killedPieces);
        return data;
    }, [killedPieces]);

    const centerSize = useMemo(
        () => getCenterSize(gameContainerWidth, gameContainerHeight),
        [gameContainerWidth, gameContainerHeight]
    );

    const topBottomSize = useMemo(
        () => getTopBottomSize(gameContainerWidth, gameContainerHeight),
        [gameContainerWidth, gameContainerHeight]
    );

    const meOrWhitePlayer = gameStore?.currentGameNew?.players.find(
        (item) => item._id === authStore.currentUser?._id
    );

    const currentChessBoardHeight = refBoard?.current?.clientHeight;

    const opponent = gameStore?.currentGameNew?.players.find(
        (item) => item._id !== authStore.currentUser?._id
    );
    const opponentTime = useMemo(() => {
        if (!gameStore?.rightBlockCollector.clockData.length) return 0;
        const getOpponentTime = !!meOrWhitePlayerIndex ? 0 : 1;
        const timeLeftMemo =
            gameStore?.rightBlockCollector.clockData[getOpponentTime];

        return timeLeftMemo || 0;
        // return viewMode
        //     ? gameStore.gameState.playerTimeLeft(yourColor)
        //     : gameStore.gameState.opponentTimeLeft;
    }, [
        viewMode,
        opponentColor,
        gameStore?.rightBlockCollector.clockData,
        gameStore.gameId,
        activeClock,
        myMove,
    ]);
    const getCurrentCashbox = useMemo(() => {
        if (balanceType === 0) {
            return userCoinBalance;
        } else {
            return userFreeCoinBalance;
        }
    }, [balanceType]);
    const isParticipateInTheGame = isGamePage && myColor !== ColorVariant.none;
    const activeDoublingModal =
        !gameStore.isShowWinOrLostDialog &&
        !waitingBetApply &&
        curMove !== curChessMove &&
        myMove;
    const solidMultiGames = [
        ...getAllMyParticipatingGames,
        ...tournamentsStore.myGamesList,
    ].filter((game) => {
        return (
            game &&
            game.status === GameStatus.IN_PROGRESS &&
            !location.pathname.includes(game.id)
        );
    });
    const isActivePlayer = (color, clock) =>
        shortColor(color) === _get(clock, "color", "unknown");

    //USE STATE
    const [elemHeight, setHeight] = useState<number | undefined>();
    const uniqWatchers = gameStore.currentGameNew?.viewers || [];
    const [randomValue, setRandomValue] = useState<number>(1);
    const [isOpenWatchers, setOpenWatchers] = useState<
        boolean | WatchersStatusType
    >(false);
    const [chessBoardHeight, setChessBoardHeight] = useState<
        number | undefined
    >(undefined);

    const [animateChipsLeft, setChipsAnimateLeft] = useState<{
        [key: string]: number | boolean;
    }>({
        vertical: false,
        horizontal: false,
    });
    const [animateChipsRight, setChipsAnimateRight] = useState<{
        [key: string]: number | boolean;
    }>({
        vertical: false,
        horizontal: false,
    });

    const classes = useStyles({
        elemHeight,
    });

    useEffect(() => {
        if (doublingAcceptedByOponent) {
            let runTimeout;
            runTimeout = setTimeout(() => {
                gameStore.dialogs.closeDoublingDialog();
            }, 500);
            return () => clearInterval(runTimeout);
        }
    }, [doublingAcceptedByOponent]);

    const calcFinalMatch = (resetFuntion?: () => void) => {
        if (gameStore.currentGameNew?.type === GameModeTypeNew.REGULAR)
            return false;
        if (gameSeries) {
            if (gameSeries - firstPlayer! === 0) {
                resetFuntion && resetFuntion();
                return true;
            }
            if (gameSeries - secondPlayer! === 0) {
                resetFuntion && resetFuntion();
                return true;
            }
            return false;
        }
        return false;
    };

    // const yourTime = useMemo(() => {
    //     return viewMode
    //         ? gameStore.gameState.playerTimeLeft(yourColor)
    //         : gameStore.gameState.opponentTimeLeft;
    // }, [viewMode, yourColor, gameStore.gameId, activeClock]);

    useEffect(() => {
        if (activeDoublingModal) {
            gameStore.dialogs.showDoublingDialogNew();
        }
    }, [activeDoublingModal]);

    useLayoutEffect(() => {
        if (winner) {
            gameStore.dialogs.closeSelectFiguresDialog();
            gameStore.dialogs.closeDoublingDialog();
            // setInit1(false);
            // setInit2(false);
        }
    }, [winner]);

    useEffect(() => {
        if (doubleThinking || showOpponentAcceptedDoublingDialog) {
            let generateRandomNumber = (Math.random() * 10) | 0;
            if (generateRandomNumber && randomValue === generateRandomNumber) {
                setRandomValue(9 - generateRandomNumber);
            } else {
                setRandomValue(generateRandomNumber);
            }
        }
    }, [doubleThinking, showOpponentAcceptedDoublingDialog]);



    useEffect(() => {
        let clearWatchers;
        if (isOpenWatchers === WatchersStatusType.hide) {
            clearWatchers = setTimeout(() => {
                setOpenWatchers(false);
            }, 500);
        }
        return () => {
            if (clearWatchers) {
                clearTimeout(clearWatchers);
            }
        };
    }, [isOpenWatchers]);

    // const getCurrentCashbox = useMemo(() => {
    //     if (balanceType === UserBalanceType.play) {
    //         return userCoinBalance;
    //     } else {
    //         return userFreeCoinBalance;
    //     }
    // }, [balanceType]);

    useEffect(() => {
        let actualSize = calcDiceSize(
            gameContainerWidth,
            diceWrapperRef?.current?.offsetHeight
        );
        gameStore.setPiecesSize(actualSize, ScreenType.mobile);
    }, [
        gameContainerWidth,
        gameContainerHeight,
        diceWrapperRef?.current?.offsetWidth,
        diceWrapperRef?.current?.offsetHeight,
    ]);

    useEffect(() => {
        if (user?._id === curChessMove && curMove !== curChessMove) {
            return gameStore.dialogs.openThinkingDialog();
        }

        return gameStore.dialogs.closeThinkingDialog();
    }, [curMove, curChessMove, user]);

    const [thinkingState, setThinkState] = useState<boolean>(false);

    useEffect(() => {
        if (doubleThinking) {
            setThinkState(true);
        }
        if (!!thinkingState && !doubleThinking) {
            setTimeout(() => {
                setThinkState(false);
            }, 1800);
        }
    }, [curChessMove, doubleThinking, thinkingState]);

    if (!opponent || !meOrWhitePlayer) return;

    const opponentMock = {
        color: ColorVariant.black,
        id: opponent._id,
        name: opponent?.nickname || "opponent",
        rating: 0,
        score: 0,
        winInGames: 0,
        ...opponent,
    };

    const meOrWhitePlayerMock = {
        color: ColorVariant.white,
        id: meOrWhitePlayer._id,
        name: meOrWhitePlayer?.nickname || "Me",
        rating: 0,
        score: 0,
        winInGames: 0,
        ...meOrWhitePlayer,
    };

    if (window.innerWidth <= MIN_XL_TABLET) {
        return (
            <>
                {!isShowWinOrLostDialog && dialogsAreHide && (
                    <WatchingList
                        setOpen={(properties) => setOpenWatchers(properties)}
                        openStatus={WatchersStatusType.hide}
                        isOpen={isOpenWatchers}
                        watchingArray={uniqWatchers}
                        isMobileGamePage
                        elementPlace={PlayerInfoExistPosition.inProgressPreview}
                    />
                )}
                <Box
                    component="div"
                    // className={cn({
                    //     [classes.mainMobileGameContainerNew]: !resign,
                    // })}
                    className={cn(classes.mainMobileGameContainerNew)}
                    style={{ gridTemplateRows: `1fr ${centerSize} 1fr` }}
                >
                    <div
                        className={classes.mobileTabletTopBox}
                        style={{ height: topBottomSize }}
                    >
                        <div
                            className={cn(
                                classes.playerInfoWrapperNew,
                                classes.opponentInfoWrapperNew
                            )}
                        >
                            <Box
                                component="div"
                                className={cn(
                                    classes.presentAndNavigationBox,
                                    "top",
                                    {
                                        isTournamentGame: isTournamentGame,
                                        isMatchGame: isMatchGame,
                                    }
                                )}
                            >
                                {isMultiRoundGame && (
                                    <GameScoreRedesign
                                        winner={winner}
                                        stage={
                                            gameStore?.currentGame?.tournament
                                                ?.stage
                                        }
                                        title={
                                            gameStore?.currentGame?.tournament
                                                ?.title
                                        }
                                    />
                                )}
                                {!isMultiRoundGame &&
                                    XL_Tablet &&
                                    isParticipateInTheGame && (
                                        <>
                                            <div
                                                className={cn(
                                                    classes.bankWrapperNew,
                                                    {
                                                        activeInit: isLeftBet,
                                                        isWinnerWithReject:
                                                            isLeftBet &&
                                                            !isRightBet &&
                                                            winner,
                                                        acceptedAndFinished:
                                                            isLeftBet &&
                                                            isRightBet &&
                                                            !winner,
                                                        isWinnerAndHideBank:
                                                            winner &&
                                                            isRightBet,
                                                        solidWinner:
                                                            winner &&
                                                            !isLeftBet &&
                                                            !isRightBet,
                                                    }
                                                )}
                                            >
                                                {/* {gameStore.currentGame?.settings
                                                ?.rounds! === 1 &&
                                                gameType ===
                                                    GameModeTypeNew.REGULAR && (
                                                    <MobilePot
                                                        isGamePage={
                                                            !!isGamePage
                                                        }
                                                        chessboardType={
                                                            ChessboardType.mobileGame
                                                        }
                                                    >
                                                        <CoinAnimationContainer
                                                            balanceType={
                                                                balanceType
                                                            }
                                                            isMultiTable={
                                                                isMultiTable
                                                            }
                                                            chessboardType={
                                                                ChessboardType.mobileGame
                                                            }
                                                        />
                                                    </MobilePot>
                                                )} */}

                                                <RiseAndCoinAnimation />
                                            </div>
                                        </>
                                    )}
                                {XL_Tablet &&
                                    isParticipateInTheGame &&
                                    gameStore.isShowWinOrLostDialog &&
                                    userRakeBack &&
                                    gameType === GameModeTypeNew.REGULAR && (
                                        <RakeBackModal />
                                    )}
                            </Box>

                            <Box
                                component="div"
                                className={cn(
                                    classes.playerInfoInnerWrapperNew,
                                    { viewMode, isMultiTable }
                                )}
                            >
                                {XL_Tablet && !viewMode && (
                                    <div
                                        className={cn(
                                            classes.dicesAndAlertHolderNew,
                                            {
                                                inProgress: isMultiRoundGame,
                                            }
                                        )}
                                    >
                                        <Box
                                            component="div"
                                            className={
                                                classes.dicesAlertsBoxNew
                                            }
                                        >
                                            {!gameStore.isShowWinOrLostDialog &&
                                                !waitingBetApply &&
                                                curMove !== curChessMove &&
                                                myMove && (
                                                    <MobileX2Alert
                                                        randomValue={
                                                            randomValue
                                                        }
                                                    />
                                                )}
                                            {/* {gameStore.dialogs
                                                .oponentThinkingDialog.isOpen &&
                                                !gameStore.dialogs.giveUpDialog
                                                    .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                !gameStore.gameState.hasSpecialActivity() &&
                                                gameType ===
                                                    GameModeTypeNew.REGULAR &&
                                                opponent && (
                                                    <MobilePlayerThinking
                                                        // player={opponent}
                                                        player={opponentMock}
                                                        randomValue={
                                                            randomValue
                                                        }
                                                    />
                                                )} */}
                                            {!gameStore.isShowWinOrLostDialog &&
                                                doubleThinking &&
                                                opponentMock && (
                                                    <MobilePlayerThinking
                                                        // player={opponent}
                                                        player={opponentMock}
                                                        randomValue={
                                                            randomValue
                                                        }
                                                    />
                                                )}
                                            {gameStore.dialogs.giveUpDialog
                                                .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                gameType !==
                                                    GameModeTypeNew.TOURNAMENT && (
                                                    <MobileGiveUpAlert />
                                                )}
                                            {gameType !==
                                                GameModeTypeNew.TOURNAMENT && (
                                                <MobileRematchDialogs />
                                            )}
                                            {isShowWinOrLostDialog &&
                                                gameType !==
                                                    GameModeTypeNew.TOURNAMENT && (
                                                    <MobileWinOrLostAlerts />
                                                )}
                                            {gameStore.dialogs.drawDialog
                                                .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                gameType ===
                                                    GameModeTypeNew.REGULAR && (
                                                    <MobileDrawDialogs />
                                                )}
                                            {gameStore.dialogs.drawDialog
                                                .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                gameType ===
                                                    GameModeTypeNew.REGULAR && (
                                                    <MobileDrawDialogs />
                                                )}
                                        </Box>
                                        <div
                                            ref={diceWrapperRef}
                                            className={cn(
                                                classes.dicesWrapperNew,
                                                {
                                                    // isTournamentGame:
                                                    //     isMultiRoundGame,
                                                    // isShowWinOrLostDialog:
                                                    //     isShowWinOrLostDialog &&
                                                    //     isMultiRoundGame,
                                                    doubleThinking:
                                                        doubleThinking,
                                                }
                                            )}
                                        >
                                            {!isShowWinOrLostDialog && (
                                                <DicesWrapper
                                                    rndPrefix={props.rndPrefix}
                                                    diceClickRnd={
                                                        props.diceClickRnd
                                                    }
                                                    standOffColor={
                                                        props.standOffColor
                                                    }
                                                    myColor={myColor}
                                                    isMultiTable={isMultiTable}
                                                    viewMode={viewMode}
                                                    opponentColor={
                                                        opponentColor
                                                    }
                                                />
                                            )}

                                            {!isShowWinOrLostDialog &&
                                                !viewMode &&
                                                !gameStore.dialogs.rematchDialog
                                                    .isOpen &&
                                                !calcFinalMatch() && (
                                                    <StartMatch
                                                        rolled={
                                                            gameStore.gameIsStart
                                                        }
                                                        elemHeight={
                                                            elemHeight &&
                                                            elemHeight - 10
                                                        }
                                                        isMultitable={
                                                            isMultiTable
                                                        }
                                                    />
                                                )}
                                            {gameType ===
                                                GameModeTypeNew.TOURNAMENT &&
                                                typeof gameStage === "number" &&
                                                gameStage > 1 &&
                                                calcFinalMatch() && (
                                                    <TournamentsMatchResult
                                                        gameStage={gameStage}
                                                        winner={winner}
                                                        myColor={myColor}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={cn(classes.playerInfoBoxNew, {
                                        [classes.viewModePlayerInfoBoxNew]:
                                            viewMode,
                                        isMultiTable,
                                    })}
                                    style={{
                                        width: centerSize,
                                        minWidth: 320,
                                    }}
                                >
                                    {opponent && (
                                        <PlayerInfoRedesign
                                            // resign={resign}
                                            // winner={winner}
                                            // noTimerSound
                                            // initTime={yourTime}
                                            // active={isActivePlayer(
                                            //     yourColor,
                                            //     activeClock
                                            // )}
                                            // color={yourColor}
                                            // opponent={opponent}
                                            resign={resign || "false"}
                                            winner={winner || "false"}
                                            noTimerSound={false}
                                            avatar={opponentMock.avatar}
                                            name={
                                                opponentMock.name || "Unknown"
                                            }
                                            initTime={opponentTime}
                                            activeClock={activeClock}
                                            active={!myMove}
                                            killedPieces={killedPieces[myColor]}
                                            color={
                                                opponentColor ||
                                                ColorVariant.black
                                            }
                                            isSystemAvatar={
                                                opponent.isSystemAvatar
                                            }
                                            isMultiTable={isMultiTable}
                                            isTwoGameTable={isTwoGameTable}
                                            isOpponent={true}
                                            opponent={opponentMock}
                                            elementPlace={
                                                PlayerInfoExistPosition.inGame
                                            }
                                            piecesBeatCounter={
                                                piecesBeatCounter[opponentColor]
                                            }
                                        />
                                    )}
                                </div>
                            </Box>
                        </div>
                    </div>
                    <div
                        className={cn(classes.mobileTabletCenterBox, {})}
                        style={
                            winner ? { zIndex: -10, position: "relative" } : {}
                        }
                    >

                        <BoardV2/>
                    </div>
                    <div
                        className={classes.mobileTabletBottomBox}
                        style={{ height: topBottomSize }}
                    >
                        <div className={cn(classes.playerInfoWrapperNew)}>
                            <Box
                                component="div"
                                className={cn(
                                    classes.playerInfoInnerWrapperNew,
                                    { viewMode, isMultiTable }
                                )}
                            >
                                <div
                                    className={cn(
                                        classes.playerInfoBoxNew,
                                        classes.myInfoWrapper
                                    )}
                                    style={{
                                        width: centerSize,
                                        minWidth: 320,
                                    }}
                                >
                                    {meOrWhitePlayer && (
                                        <PlayerInfoRedesign
                                            // avatar={meOrWhitePlayer.avatar}
                                            // name={
                                            //     meOrWhitePlayer.name ||
                                            //     "Unknown"
                                            // }
                                            // killedPieces={
                                            //     killedPieces[yourColor]
                                            // }
                                            // active={isActivePlayer(
                                            //     myColor,
                                            //     activeClock
                                            // )}
                                            resign={resign}
                                            winner={winner}
                                            avatar={meOrWhitePlayerMock.avatar}
                                            name={
                                                meOrWhitePlayerMock.nickname ||
                                                "Unknown"
                                            }
                                            initTime={myTime}
                                            activeClock={activeClock}
                                            active={myMove}
                                            killedPieces={
                                                killedPieces[opponentColor] ||
                                                {}
                                            }
                                            color={myColor}
                                            reverse
                                            userCashBox={getCurrentCashbox}
                                            isSystemAvatar={
                                                user?.isSystemAvatar
                                            }
                                            isTournamentGame={
                                                gameType ===
                                                GameModeTypeNew.TOURNAMENT
                                            }
                                            isMultiTable={isMultiTable}
                                            isTwoGameTable={isTwoGameTable}
                                            elementPlace={
                                                PlayerInfoExistPosition.inGame
                                            }
                                            me={true}
                                            piecesBeatCounter={
                                                piecesBeatCounter[myColor]
                                            }
                                        />
                                    )}
                                </div>
                                {XL_Tablet && !viewMode && (
                                    <div
                                        className={cn(classes.alertHolder, {
                                            showWatchersBlock,
                                        })}
                                    >
                                        {gameStore.dialogs.selectFiguresDialog
                                            .isOpen &&
                                            !isShowWinOrLostDialog &&
                                            !gameStore.dialogs.rematchDialog
                                                .isOpen && (
                                                <SelectFiguresAlertWrapper
                                                    isTabletMobile
                                                    setNoanimation={() => {}}
                                                />
                                            )}
                                        {/* {gameStore.dialogs.doublingDialog
                                            .isOpen &&
                                            !gameStore.dialogs.giveUpDialog
                                                .isOpen &&
                                            !isShowWinOrLostDialog && (
                                                <X2Dialogs
                                                    showOpponentAcceptedDoubling={
                                                        false
                                                    }
                                                    isTabletMobile
                                                />
                                            )} */}
                                        {!gameStore.isShowWinOrLostDialog &&
                                            doublingDialogIsOpen && (
                                                <X2Dialogs />
                                            )}
                                        {gameStore.dialogs.drawDialog.isOpen &&
                                            !isShowWinOrLostDialog && (
                                                <DrawDialog isTabletMobile />
                                            )}
                                        {(gameStore.isShowWinOrLostDialog ||
                                            gameStore.dialogs.rematchDialog
                                                .isOpen) && (
                                            <div
                                                className={cn(
                                                    classes.alertHolderAnimate
                                                )}
                                            >
                                                <WinOrLostAlert
                                                    opponent={opponent}
                                                    isTabletMobile
                                                />
                                                {gameStore.isShowWinOrLostDialog &&
                                                    gameType !==
                                                        GameModeTypeNew.TOURNAMENT &&
                                                    !viewMode && (
                                                        <RematchAlerts />
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Box>

                            <Box
                                component="div"
                                className={cn(
                                    classes.presentAndNavigationBox,
                                    "bottom",
                                    {
                                        hideWhenChooseFigure:
                                            gameStore.dialogs
                                                .selectFiguresDialog.isOpen,
                                    }
                                )}
                            >
                                {!isShowWinOrLostDialog && dialogsAreHide && (
                                    <ActionAndReturn
                                        setOpen={(properties) =>
                                            setOpenWatchers(properties)
                                        }
                                        openStatus={WatchersStatusType.show}
                                        isOpen={isOpenWatchers}
                                        watchingArray={uniqWatchers}
                                        solidMultiGames={solidMultiGames}
                                        isMobileGamePage={true}
                                        elementPlace={
                                            PlayerInfoExistPosition.inGame
                                        }
                                    />
                                )}
                            </Box>
                        </div>
                    </div>
                </Box>
            </>
        );
    }
};
   //@ts-ignore
export default observer(MobileGameChessboardWrapper);
