import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#000',
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    gradientOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(180deg, rgba(5, 5, 5, 0.25) 0%, rgba(5, 5, 5, 0.98) 42%, #050505 88.12%)',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1vw 0',
        margin: '0 2.5vw',
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    },
    backButton: {
        background: 'none',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        fontSize: 20,
        display: 'flex',
        alignItems: 'center',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: 32,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        fontFamily: 'Montserrat',
    },
    coin: {
        margin: '0 0.5vw 0 1.5vw',
    },
    gradientSpan: {
        background: 'linear-gradient(180deg, #FF9604 -0.31%, #FFE522 11.9%, #FFFFC1 36.23%, #FFBB1B 65.11%, #E28800 65.12%, #E9A600 88.87%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        color: 'transparent', 
        display: 'inline-block', // Чтобы градиент применялся только к тексту
    },
    arrowDown: {
        marginLeft: '0.5vw',
    },
    arrowRight: {
        marginRight: '1vw',
        width: 10,
    },
    dateInfo: {
        fontSize: 15,
        opacity: 0.8,
        margin: '1vw 0 0.5vw 0',
        '& span': {
            fontWeight: 700, 
            margin: '0 0.5vw',
        },
    },
    content: {
        position: 'relative',
        zIndex: 1,
        padding: '1vw',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    rounds: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '2vw',
        '& span': {
            fontSize: '1rem',
        },
    },
    bracket: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
        margin: '0 2%',
        position: 'relative',
        height: 'calc(100vh - 150px)', // Подстройте это значение по необходимости
        overflowY: 'auto',
        overflowX: 'auto',
        width: '78%',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '150px',
        padding: '0 5px',
        justifyContent: 'flex-start',
        flexShrink: 0,
    },
    connectingLines: {
        top: 0,
        bottom: 0,
        right: '-8%',
        width: '16%',
        pointerEvents: 'none',
    },
    line: {
        borderRight: '2px solid rgba(255, 255, 255, 0.3)',
        borderTop: '2px solid rgba(255, 255, 255, 0.3)',
        borderBottom: '2px solid rgba(255, 255, 255, 0.3)',
        width: '100%',
    },
    columnHeader: {
        textAlign: 'start',
        padding: '1vw 0.5vw',
        fontWeight: 300,
        fontSize: 12,
        marginBottom: '1vw',
    },
    matchesContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start', // Изменено с 'space-around' на 'flex-start'
    },
    matchPair: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '0.2vw',
        overflow: 'hidden',
        width: '100%',
        marginBottom: '10px', // Добавлен небольшой отступ между парами
    },
    player: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5vw 1vw',
        fontWeight: 600,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    playerInfo: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
    },
    avatar: {
        width: 17,
        height: 17,
        borderRadius: '50%',
        marginRight: '8px',
        objectFit: 'cover',
    },
    winner: {
        borderRight: '4px solid #4CAF50', 
        fontWeight: 600,
        '& $score': {
            color: '#4CAF50', 
            marginLeft: '1vw',
        },
    },
    score: {
        fontWeight: 600,
        marginLeft: '1vw',
        fontSize: 12,
    },
}));