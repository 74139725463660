import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import { media } from "src/utils/media";
import theme from "src/theme";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";
import { windowsSizeParams } from "src/consts/window-size-params";

interface IMobileNote {
    isGamePage: any;
    bankPosition?: any;
    isPoker: boolean;
}

const height = window.innerHeight;

export const useStyles = makeStyles(() => ({
    "@global": {
        "@keyframes collapseBarPoker": {
            "0%": {
                transform: "scale(0)",
            },
            "100%": {
                transform: "scale(1)",
            },
        },

        "@keyframes collapseBarPoker2": {
            "0%": {
                transform: "scale(0)",
            },
            "100%": {
                transform: "scale(1)",
            },
        },

        "@keyframes collapseBarPoker3": {
            "0%": {
                transform: "scale(0)",
            },
            "100%": {
                transform: "scale(1)",
            },
        },
        "@keyframes gameInfoDotMain": {
            "0%": {
                opacity: 1,
                // filter: "blur(0.5vw)",
            },
            "50%": {
                opacity: 0.1,
                filter: "blur(1vw)",
            },
            "100%": {
                opacity: 1,
                // filter: "blur(0.5vw)",
            },
        },
    },

    containerNav: ({ isPoker }: IMobileNote) => ({
        minWidth: 0,
        width: "100%",
        margin: !isPoker ? "0" : "0 420px",
        position: "relative",
        background: "#151719",
        zIndex: 100,
        //padding: '0 70px',
        // zIndex: -1,
        [theme.breakpoints.down("md")]: {
            margin: "0",
        },
        [theme.breakpoints.down("sm")]: {
            background: "transparent",
            margin: "0",
        },
        [theme.breakpoints.down("xs")]: {
            position: "relative",
            top: 0,
            right: 0,
            width: "100%",
            margin: "0",
        },
        "&.isLanding": {
            background: "#151719",
        },
        "&.isPoker": {
            animationDelay: "0.3s",
            width: "100%",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "1000ms",
            transform: "scale(1)",
            top: "0",
            margin: "0",

            "@media screen and (max-width: 1594px)": {
                width: "100%",
                animationDelay: "0.5s",
                animationIterationCount: 1,
                animationFillMode: "forwards",
                animationDuration: "1000ms",
            },

            "@media screen and (max-width: 1150px)": {
                width: "100%",
                animationDelay: "0.5s",
                animationIterationCount: 1,
                animationFillMode: "forwards",
                animationDuration: "1000ms",
            },

            "@media screen and (max-width: 1024px) and (orientation: landscape)":
                {
                    display: "flex",
                    width: "100%",
                },

            "@media screen and (max-width: 1024px) and (orientation: portrait)":
                {
                    animationName: "none",
                    background: "transparent",
                    animationDelay: "2s",
                    animationIterationCount: 1,
                    animationFillMode: "forwards",
                    animationDuration: "2000ms",
                    height: windowsSizeParams.largeTabletAppBarHeight,
                    display: "flex",
                    alignItems: "center",
                    paddingTop: 5,
                    width: "100%",
                    transform: "scale(1)",
                    top: "0",
                    padding: "0",
                },

            "@media screen and (max-width: 768px) and (orientation: portrait)":
                {
                    animationName: "none",
                    background: "transparent",
                    opacity: 1,
                    animationDelay: "3s",
                    animationIterationCount: 1,
                    animationFillMode: "forwards",
                    animationDuration: "1500ms",
                    height: windowsSizeParams.largeTabletAppBarHeight,
                    paddingTop: 3,
                    maskImage: "none",
                    transform: "scale(1)",
                    top: "0",
                    width: "100%",
                },

            "@media screen and (max-width: 480px) and (orientation: portrait)":
                {
                    opacity: 1,
                    animationName: "none",
                    animationDelay: "3s",
                    animationIterationCount: 1,
                    animationFillMode: "forwards",
                    animationDuration: "1500ms",
                    margin: 0,
                    height: windowsSizeParams.mobileAppBarHeight,
                    transform: "scale(1)",
                    top: "0",
                    width: "100%",
                },

            "@media screen and (max-width: 460px)": {
                position: "relative",
                top: 0,
                right: 0,
                width: "100%",
                margin: "0",
                transform: "scale(1)",
            },
        },
        "&.isPurchaseDiceCoins": {
            zIndex: -1,
        },
        "&.darkBlue": {
            [theme.breakpoints.down("lg")]: {
                background: "#151719",
            },
        },
        "&.darkGreen": {
            [theme.breakpoints.down("lg")]: {
                background: "#151719",
            },
        },
        "&.darkViolet": {
            [theme.breakpoints.down("lg")]: {
                background: "#151719",
            },
        },
        "&.deepBlack": {
            [theme.breakpoints.up("lg")]: {
                //maxHeight: windowsSizeParams.desktopAppBarHeightNew,
                //height: 'auto',
            },
        },
    }),

    headerCell: {
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "flex",
            justifyContent: "flex-start !important",
            // position: 'absolute',
            // left: '40px',
            // top: '50%',
            // transform: 'translate(0%, -50%)',
            // zIndex: 1,
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            justifyContent: "center !important",
        },
    },

    burgerRatingWrapper: {
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        //    gridGap: "1vw",
    },

    jackpotIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    jackpotIcon: {
        width: "24px",
        height: "auto",
    },

    crownIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    crownIcon: {
        width: "24px",
        height: "auto",
    },

    logoWithNav: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridColumnGap: "2vw",
        //marginLeft: "2vw",
        "&.isMultitablePage": {
            marginRight: "auto",
        },
        "@media screen and (max-width: 1440px)": {
            gridColumnGap: "1vw",
        },
        "@media screen and (max-width: 1370px) and (min-width: 1024px)": {
            gridColumnGap: "1vw",
        },
        "@media screen and (max-width: 1024px)": {
            gridColumnGap: "2vw",
            //gridTemplateColumns: "auto",
            justifyContent: "center",
            marginLeft: 0,
        },
        "&.isPoker": {
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            justifyItems: "start",
            width: "calc(188px + 140*(1080vw - 1024px) /(1920 - 1024))",
            height: "calc(28px + 30*(1080vw - 1024px) /(1920 - 1024))",
            "@media screen and (max-width: 1920px)": {
                width: "calc(188px + 140*(100vw - 1024px) /(1920 - 1024))",
                height: "calc(28px + 30*(100vw - 1024px) /(1920 - 1024))",
            },
            "@media screen and (max-width: 1024px)": {
                width: "188px",
                height: "28px",
            },
        },
        [theme.breakpoints.up(1350)]: {
            "&.isMultitablePage": {
                display: "grid",
                gridArea: "logo",
                justifyContent: "center",
                alignItems: "center",
                justifySelf: "center",
                gridTemplateColumns: "1fr",
            },
        },
    },
    menuLayer: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },

    logoContainerUnauthorized: {
        marginLeft: "2vw",
    },

    logo: {
        width: media(195, 320, 1024),
        height: media(28, 50, 1024),
        objectFit: "contain",
        cursor: "pointer",
        zIndex: 3,
        [theme.breakpoints.down("lg")]: {
            width: 195,
            height: 28,
        },
        [theme.breakpoints.down("md")]: {
            width: 182,
            height: 26,
        },
    },

    navMenu: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        flexGrow: 1,
        fontSize: 20,
        fontWeight: 500,
        textDecoration: "none",
        color: appColors.txt400,
    },

    navMenuItemBox: {
        position: "relative",
        overflow: "visible",
        "&:hover $blurOnHover": {
            opacity: 1, // Show image on hover for active links
            transform: "translate(-50%, -50%) scale(1.1)",
        },
        "&$disabled": {
            pointerEvents: "none", // Disable pointer events for inactive links
        },
    },

    gameInfoDot: {
        display: "block",
        width: 8,
        height: 8,
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        right: "0%",
        transform: "translate(-100%, -20%)",
        background: "#61DB8B",
        zIndex: -1,
        animationName: "gameInfoDotMain",
        // animationDelay: "0.3s",
        animationIterationCount: "infinite",
        animationFillMode: "forwards",
        animationDuration: "3000ms",
        opacity: 1,
        // "&:after": {
        //     content: '""',
        //     display: "block",
        //     width: 10,
        //     height: 10,
        //     borderRadius: "50%",
        //     position: "absolute",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     background: "#61DB8B",
        //     zIndex: -1,
        //     animationName: "$gameInfoDot",
        //     animationDelay: "0.3s",
        //     animationIterationCount: "infinite",
        //     animationFillMode: "forwards",
        //     animationDuration: "4000ms",
        // },
    },

    "@keyframes gameInfoDot": {
        "0%": {
            transform: "translate(-50%, -50%) scale(1)",
            opacity: 0,
        },
        "50%": {
            transform: "translate(-50%, -50%) scale(2)",
            opacity: 0.5,
            filter: "blur(1vw)",
        },
        "100%": {
            transform: "translate(-50%, -50%) scale(1)",
            opacity: 0,
        },
    },

    navMenuItem: {
        fontSize: 18,
        fontWeight: 500,
        textDecoration: "none",
        color: appColors.txt400,
        margin: "0 1vw",
        whiteSpace: "nowrap",
        position: "relative",
        zIndex: 1,
        transition: "color 0.3s ease",
        "@media screen and (max-width: 1400px)": {
            margin: "0 0.8vw",
        },
        "@media screen and (max-width: 1150px)": {
            margin: "0 0.8vw",
            fontSize: 16,
        },
        "@media screen and (max-width: 1024px)": {
            margin: "0 1vw",
            fontSize: 16,
        },
        "@media screen and (max-width: 768px)": {
            margin: "0 1vw",
            fontSize: 16,
        },
        "&.tournaments": {
            animationName: "$emptyTournamentsMessage",
            animationDelay: "1s",
            animationIterationCount: "infinite",
            animationFillMode: "forwards",
            animationDuration: "5000ms",
        },
    },

    blurOnHover: {
        opacity: 0,
        width: 190,
        position: "absolute",
        left: "60%",
        top: "50%",
        zIndex: 0,
        transition: "opacity 0.3s, ease-in-out",
        transform: "translate(-50%, -50%)",
    },

    "@keyframes dotPulse": {
        "0%": {
            opacity: 0.5,
        },
        "35%": {
            opacity: 1,
            transform: "scale(1.03)",
        },
        "100%": {
            transform: "scale(1.00)",
            opacity: 0.5,
        },
    },

    returnGame: {
        position: "absolute",
        top: 10,
        paddingLeft: 104,

        display: "inline",
        zIndex: 1000,
    },

    infoGrid: {
        color: "white",
        position: "relative",
        flexGrow: 0,
        marginLeft: "auto",
        "&.isMultitablePage": {
            marginLeft: "auto",
            paddingLeft: "2vw",
        },
        "@media screen and (max-width: 1024px)": {
            //justifySelf: "flex-end",
        },
        [theme.breakpoints.up(1350)]: {
            "&.isMultitablePage": {
                gridArea: "user",
                //justifySelf: "flex-end",
            },
        },
    },
    appBarElement: {
        position: "relative",
        minWidth: 150,
        maxWidth: "33%",
        width: "auto",
        height: "100%",
        display: "flex",
        alignItems: "center",
    },

    splashWrapper: {
        position: "absolute",
        right: -36,
    },
    "@media screen and (max-width: 1920px)": {
        appBar: {
            height: 80,
            background: "rgba(5, 5, 5, 1)",
        },
        menuLayer: {
            display: "flex",
            alignItems: "center",
            height: "100%",
        },

        logo: {
            height: 41,
            objectFit: "contain",
        },

        navMenu: {
            display: "flex",
            height: "100%",
            alignItems: "center",
            fontWeight: 500,
            textDecoration: "none",
            color: appColors.txt400,
        },

        returnGame: {
            position: "absolute",
            top: 10,
            paddingLeft: 104,
            display: "inline",
            zIndex: 1000,
        },
        infoGrid: {
            textAlign: "right",
            color: "white",
            position: "relative",
        },
    },
    "@media screen and (max-width: 1536px)": {
        appBar: {
            height: "2vw",
            background: "rgba(5, 5, 5, 1)",
        },
        menuLayer: {
            display: "flex",
            alignItems: "center",
            height: "100%",
        },

        logo: {
            height: 41,
            objectFit: "contain",
        },

        navMenu: {
            display: "flex",
            height: "100%",
            alignItems: "center",
            fontWeight: 500,
            textDecoration: "none",
            color: appColors.txt400,
        },

        infoGrid: {
            textAlign: "right",
            color: "white",
            position: "relative",
        },
    },

    "@media screen and (max-width: 1200px)": () => ({
        appBar: {
            height: 80,
            background: "rgba(5, 5, 5, 1)",
        },
        menuLayer: {
            display: "flex",
            alignItems: "center",
            height: "100%",
        },

        logo: {
            height: 41,
            objectFit: "contain",
        },

        navMenu: {
            display: "flex",
            height: "100%",
            alignItems: "center",
            fontSize: 20,
            fontWeight: 500,
            textDecoration: "none",
            color: appColors.txt400,
        },

        returnGame: {
            position: "absolute",
            top: 10,
            paddingLeft: 104,
            display: "inline",
            zIndex: 1000,
        },

        appBarElement: {
            minWidth: 110,
            transform: "scale(0.5)",
        },

        infoGrid: {
            textAlign: "right",
            color: "white",
            position: "relative",
        },
    }),

    bankWrapper: {
        display: "flex",
        alignItems: "center",
    },

    bankCoinIcon: {
        width: "2vw",
        height: "2vw",
        objectFit: "contain",
        [theme.breakpoints.between("sm", "xl")]: {
            [`@media screen and screen and (orientation: portrait) `]: {
                width: "1vw",
                height: "1vw",
                objectFit: "contain",
            },
        },
    },

    coinIcon: {
        width: "0.5vw",
        height: "0.5vw",
    },

    backPage: {
        [theme.breakpoints.down("lg")]: {
            display: "inline-flex",
            position: "fixed",
            color: "white",
            left: 20,
            top: 20,
            border: "1px solid white",
            padding: "2px 4px",
            borderRadius: 20,
        },

        display: "none",
    },

    appBar: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 78,
        background: "rgba(5, 5, 5, 1)",
        "&.isMultitablePage": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        [theme.breakpoints.up(1350)]: {
            "&.isMultitablePage": {
                display: "grid",
                //gridTemplateAreas: '". logo user"',
                gridTemplateColumns: "repeat(3, 1fr)",
                height: "4vw",
            },
        },
        [theme.breakpoints.down(1250)]: {
            justifyContent: "space-between",
            padding: "0 10px 0 10px",
            position: "relative",
        },
        [theme.breakpoints.down(1000)]: {
            paddingBottom: "0",
            display: "flex",
            alignItems: "center",
            "&.isGamePage": {
                // height: "3vw",
                [theme.breakpoints.down("lg")]: {
                    height: windowsSizeParams.largeTabletAppBarHeight,
                },
                [theme.breakpoints.down("md")]: {
                    height: windowsSizeParams.mobileAppBarHeight,
                },
            },
        },
        [theme.breakpoints.down("lg")]: {
            // height: 55,
            "&.slotPage": {
                height: 60,
                display: "none",
            },
        },
        "&.center": {
            display: "flex",
            justifyContent: "center",
            gridTemplateColumns: "unset",
            minHeight: 80,
            padding: "1vw 0",
        },
        [theme.breakpoints.down("md")]: {
            // display: 'grid',
            gridTemplateColumns: "3vw 0 1fr  1fr",
            //This properties for filter icon, if we will recosider return turn on
            // gridTemplateColumns: '50px 1fr 3fr 1fr',
            // justifyContent: 'unset',
            // alignItems: 'center',
            // height: '68px',
            //height: "3vw",
            paddingTop: "0",
            // paddingBottom: '4px',
            paddingBottom: "0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "&.isGamePage": {
                height: "3vw",
                [theme.breakpoints.down("lg")]: {
                  height: windowsSizeParams.largeTabletAppBarHeight,
              },
              [theme.breakpoints.down("md")]: {
                  height: windowsSizeParams.mobileAppBarHeight,
              },
            },
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0 10px 0 10px",
            height: windowsSizeParams.mobileAppBarHeight,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            //    marginTop: '1vw',
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0",
            paddingRight: "2vw",
        },
        "&.isPoker": {
            //display: "grid",
            //gridTemplateColumns: "repeat(3, 1fr)",
            //height: "4vw",
            //[theme.breakpoints.down(1250)]: {
            //    padding: "0 1vw",
            //    position: "relative",
            //    "@media screen and (orientation: portrait)": {
            //        justifyContent: "center",
            //        margin: "0 auto",
            //    },
            //},
            //[theme.breakpoints.down("lg")]: {
            //    padding: 0,
            //    height: windowsSizeParams.largeTabletAppBarHeight,
            //    paddingTop: 0,
            //    "@media screen and (orientation: portrait)": {
            //        justifyContent: "center",
            //        margin: "0 auto",
            //    },
            //},
            //[theme.breakpoints.down("md")]: {
            //    padding: 0,
            //    height: windowsSizeParams.largeTabletAppBarHeight,
            //    paddingTop: 0,
            //    paddingRight: 0,
            //    "@media screen and (orientation: portrait)": {
            //        justifyContent: "center",
            //        margin: "0 auto",
            //    },
            //},
            //[theme.breakpoints.down("sm")]: {
            //    padding: 0,
            //    height: windowsSizeParams.mobileAppBarHeight,
            //    paddingTop: 0,
            //    paddingRight: 0,
            //    "@media screen and (orientation: portrait)": {
            //        justifyContent: "center",
            //        margin: "0 auto",
            //    },
            //},
        },
    },

    fullScreenWrapper: {
        position: "absolute",
        top: "50%",
        right: "-2vw",
        zIndex: 2,
        cursor: "pointer",
        transform: "translate(0%, -50%) scale(0.90)",
        transition: "transform 150ms ease-out",
        opacity: 0.7,
        "&:hover": {
            opacity: 1,
            transform: "translate(0%, -50%) scale(1)",
            transition: "transform 150ms ease-in",
        },
        [theme.breakpoints.down("xl")]: {
            right: "-3vw",
            opacity: 0.7,
            transform: "translate(0%, -50%) scale(0.75)",
            transition: "transform 150ms ease-out",
            "&:hover": {
                opacity: 1,
                transform: "translate(0%, -50%) scale(0.85)",
                transition: "transform 150ms ease-in",
            },
        },
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
            opacity: 0.7,
            right: "-2vw",
            transform: "translate(0%, -50%) scale(0.85)",
            transition: "transform 150ms ease-out",
            "&:hover": {
                opacity: 1,
                transform: "translate(0%, -50%) scale(0.95)",
                transition: "transform 150ms ease-in",
            },
        },
        [theme.breakpoints.down(1151)]: {
            opacity: 0.8,
            right: "-1vw",
        },
    },

    leftSection: {
        display: "flex",
        alignItems: "center",
        flex: "1 0 auto",
        marginLeft: '2vw',
    },
    centerSection: {
        flex: "0 1 auto",
        flexGrow: 10000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1vw",
    },
    rightSection: {
        display: "flex",
        alignItems: "center",
        flex: "1 0 auto",
        //    marginLeft: '3vw',
    },

    authWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexGrow: 1,
        marginRight: "4vw",
        [`@media (min-width: 2500px)`]: {
            marginRight: "3vw",
        },
        [`@media (min-width: 1024px) and (max-width: 1440px)`]: {
            marginRight: "4vw",
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            marginRight: "0",
        },
    },

    registration: {
        display: "flex",
        gap: "1vw",
        alignItems: "center",
    },

    languageWrapper: {
        marginLeft: "1vw",
    },

    signInBtn: {
        borderRadius: "0.2vw",
        border: "none",
        background: "#00BA6C",
        fontSize: "1.2vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.2vw",
        padding: "0.8vw",
        color: "#11141C",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.9,
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            fontSize: 14,
            padding: "1.5vw",
            borderRadius: "0.5vw",
            borderWidth: "1px",
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            fontSize: 9,
            padding: "3.1vw",
            borderRadius: "1vw",
            borderWidth: "1px",
        },
    },
    logInBtn: {
        borderRadius: "0.2vw",
        border: "2px solid #00BA6C",
        background: "transparent",
        fontSize: "1.2vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.2vw",
        padding: "0.75vw",
        color: "#00BA6C",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.9,
        },
        [`@media (min-width: 760px) and (max-width: 1024px)`]: {
            fontSize: 14,
            padding: "1.4vw",
            borderRadius: "0.5vw",
            borderWidth: "1px",
        },
        [`@media (min-width: 300px) and (max-width: 701px)`]: {
            fontSize: 9,
            padding: "3vw",
            borderRadius: "1vw",
            borderWidth: "1px",
        },
    },
}));
