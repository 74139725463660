import React, { useState, useEffect } from "react";
import cn from "classnames";

import useChangeTheme from "src/hooks/useChangeTheme";
import { ColorVariant } from "src/store/models";

import { useStyles } from "./styles";

const LETTERS = ["a", "b", "c", "d", "e", "f", "g", "h"];

interface IHNotationProps {
    orientation;
    borderWidth;
    isMultiTable?: boolean;
    cellSize?: number | undefined;
}

export const HNotation = ({
    orientation,
    borderWidth,
    isMultiTable,
    cellSize,
}: IHNotationProps) => {
    const [notation, setNotation] = useState<string[]>([]);
    const appearanceTheme = useChangeTheme();
    const classes = useStyles({ borderWidth, cellSize });

    useEffect(() => {
        setNotation(
            orientation === ColorVariant.white
                ? LETTERS
                : LETTERS.slice().reverse()
        );
    }, [orientation]);

    return (
        <div
            className={cn(classes.wrapperHNotation, appearanceTheme, {
                isMultiTable,
            })}
        >
            {notation.map((n, index) => (
                <p
                    className={cn(classes.hNotationItem, appearanceTheme, {
                        isMultiTable,
                        odd: (index + 1) % 2 === 0,
                    })}
                    key={n}
                >
                    {n}
                </p>
            ))}
        </div>
    );
};
